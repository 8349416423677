import React, {useState} from 'react';
import {Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import ShopTiers from "../Beat/ShopTiers";

const AddToCartModal = ({children, beat, open, handleClose}) => {

    return (
        <>
            {children}
            <Modal
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{overflow: "scroll"}}
            >
                <Container maxWidth={"md"} component={Paper} sx={{my: 4, py: 2}}>
                    <Grid container alignItems={"center"} columns={24}>
                        <Grid item xs={22} md={23}>
                            <Typography variant={"h4"} sx={{my: 2}}>
                                Choose License Type
                            </Typography>
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <IconButton sx={{margin: "auto"}} onClick={() => handleClose()}>
                                <Close/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <ShopTiers beat={beat}/>
                </Container>
            </Modal>
        </>

    );
};

export default AddToCartModal;