import React from 'react';
import {Box, Button, IconButton} from "@mui/material";
import CartBadge from "../Cart/Badge";
import {Login, ShoppingCart} from "@mui/icons-material";
import {routes} from "../../utils/routes";
import {useNavigate} from "react-router-dom";

const NavBarActionButtons = () => {
    const navigate = useNavigate();

    return (
        <Box>
            {/*<IconButton aria-label="cart" onClick={() => navigate(routes.login)}>*/}
            {/*    <Login/>*/}
            {/*</IconButton>*/}
            <Button onClick={() => navigate(routes.login)}>
                Log In
            </Button>
        </Box>
    );
};

export default NavBarActionButtons;