import React from 'react';
import {Grid} from "@mui/material";
import AddBeatButton from "./AddBeatButton";
import SearchField from "./SearchField";

const Header = ({control, onSearch, onAddBeat}) => {
    return (
        <form onSubmit={onSearch}>
            <Grid container sx={{my: 2, px: 2}} alignItems={"center"}>
                <Grid item xs={12} md={4}>
                    <AddBeatButton onAddBeat={onAddBeat}/>
                </Grid>
                <Grid item xs={0} md={4}>
                    {/*<AddBeatButton onAddBeat={onAddBeat}/>*/}
                </Grid>
                <Grid item xs={12} md={4}>
                    <SearchField control={control} name={"search"} label={"Search"} />
                </Grid>
            </Grid>
        </form>

    );
};

export default Header;