export const checkSize = (value) => {
    if (value) {
        return value.size <= 5000000
    }
    return true
}

export const checkFileSize = (value) => {
    if (value) {
        return value.size <= 500000000
    }
    return true
}

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png", "application/octet-stream"];


export const checkFormat = (value) => {
    if (value) {
        return SUPPORTED_FORMATS.includes(value.type)
    }
    return true
}

export const extractFileNameFromURL = (url) => {
    let regex = /\/([^/]+)$/;

    let match = url.match(regex);

    if (match && match[1]) {
        return match[1]
    } else {
       return null
    }
}