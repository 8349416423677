import React from 'react';
import {Button, Grid, InputAdornment, TextField} from "@mui/material";
import {Controller} from "react-hook-form";
import {PriceNumberFormat} from "../../../../../utils/formatInput";
import {extractFileNameFromURL} from "../../../../../utils/formUtils";
import SubmitButton from "../../../../SubmitButton";
import ButtonWithConfirmation from "../../../../ButtonWithConfirmation";
import ControlledTextField from "../../../../ControlledTextField";

const BeatFilesFields = (
    {
        onSelectFile,
        freeTier,
        beatFile = null,
        fileInput,
        loadingSubmit,
        loadingDeleteBeatFile = false,
        formState,
        reset,
        onDelete,
        control
    }) => {
    return (
        <Grid container alignItems={"center"} spacing={2}>
            <Grid item xs={4} md={2} sx={{visibility: freeTier ? "hidden" : "visible"}}>
                <ControlledTextField
                    name={"price"}
                    control={control}
                    label={"Price"}
                    inputProps={{
                        inputComponent: PriceNumberFormat,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}/>
            </Grid>
            <Grid item xs={8} md={3}>
                <Controller
                    name={"file"}
                    control={control}
                    render={({field: {onChange, value}, fieldState: {error}}) => (
                        <>
                            <TextField
                                variant={"standard"}
                                label={"File"}
                                fullWidth
                                value={value ? value.name : beatFile && beatFile.aws_files_url ? extractFileNameFromURL(beatFile.aws_files_url) : ""}
                                error={!!error}
                                helperText={error ? error.message : " "}
                                disabled={true}
                            />
                        </>

                    )}
                />
                <input
                    style={{display: 'none'}}
                    accept=".zip,.rar,.7zip"
                    id={`preview-img`}
                    type="file"
                    onChange={onSelectFile}
                    ref={fileInput}
                />
            </Grid>
            <Grid item xs={12} md={2}>
                <Button
                    fullWidth
                    variant={"contained"}
                    onClick={() => fileInput.current.click()}
                >
                    Select FIle
                </Button>
            </Grid>
            <Grid item xs={12} md={2}>
                <SubmitButton
                    fullWidth
                    variant={"contained"}
                    color={"success"}
                    type={"submit"}
                    disabled={!formState.isDirty}
                    loading={loadingSubmit}
                    text={"Save"}
                />
            </Grid>
            <Grid
                item
                xs={6}
                md={1}
                sx={{
                    display: beatFile ? "block" : {xs: "none", sm: "block"},
                    visibility: beatFile ? "visible" : "hidden"
                }}
            >
                <ButtonWithConfirmation
                    text={"delete"}
                    onConfirm={() => onDelete()}
                    loading={loadingDeleteBeatFile}
                />
            </Grid>
            <Grid item xs={beatFile ? 6 : 12} md={1}>
                <Button
                    fullWidth
                    variant={"outlined"}
                    color={"warning"}
                    disabled={!formState.isDirty}
                    onClick={() => reset()}
                >
                    undo
                </Button>
            </Grid>
        </Grid>
    );
};

export default BeatFilesFields;