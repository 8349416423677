import React from 'react';
import {NumericFormat, PatternFormat} from 'react-number-format';

export const PriceNumberFormat = React.forwardRef(function NumberFormatCustom(props, ref) {
    const {onChange, ...other} = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            // thousandSeparator
            decimalSeparator={"."}
            decimalScale={2}
            fixedDecimalScale
        />
    );
});

export const NumberFormat = React.forwardRef(function NumberFormatCustom(props, ref) {
    const {onChange, ...other} = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
        />
    );
});

export const DurationNumberFormat = React.forwardRef(function NumberFormatCustom(props, ref) {
    const {onChange, ...other} = props;
    return (
        <PatternFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="#:##"
        />
    );
});