import React, {useState} from 'react';
import {Button, Grid, Paper, Typography} from "@mui/material";
import BeatsAddToCartButton from "../Beats/BeatsAddToCartButton";
import AddToCartButtonWithPrice from "./AddToCartButtonWithPrice";
import {cartService} from "../../api/services/cartService";
import {setSnack} from "../../store/actions/snack";
import {connect} from "react-redux";
import {fetchCartItems} from "../../store/actions/cart";
import {useNavigate} from "react-router-dom";
import {routes} from "../../utils/routes";

const ShopTier = ({beatFile, label, contents, setSnack, fetchCartItems, cartItems, isAuthenticated}) => {
    const [loading, setLoading] = useState(false)
    // const available = !!beatFile ? beatFile.available : false
    const navigate = useNavigate()
    const onAddToCart = () => {
        if (!isAuthenticated) {
            console.log("LOGIN")
            navigate(routes.login)
            setSnack("You must be logged in to buy beats!", "warning")
            return
        }

        if (beatFile.tier.name !== "free") {
            setLoading(true)
            cartService.addItemToCart(beatFile.id)
                .then((response) => {
                    fetchCartItems()
                    setLoading(false)
                    setSnack(response.message, "success")
                })
                .catch((errorResponse) => {
                    setSnack(errorResponse.message, "error")
                    setLoading(false)
                })
        }
    }

    const alreadyPurchased = cartItems.some(cartItem => !!beatFile ? cartItem.beat_file.id === beatFile.id : false)

    return (
        <Paper sx={{my: 2, p: 2}}>
            <Grid container alignItems={"center"}>
                <Grid item xs={7} md={9}>
                    <Typography variant={"h4"}>
                        {label}
                    </Typography>
                    <Typography color={"text.secondary"}>
                        {contents}
                    </Typography>
                </Grid>
                <Grid item xs={5} md={3}>
                    <AddToCartButtonWithPrice
                        disabled={!beatFile}
                        onAddToCart={onAddToCart}
                        price={!!beatFile ? beatFile.price : 0}
                        loading={loading}
                        alreadyPurchased={alreadyPurchased}

                    />
                </Grid>
            </Grid>
        </Paper>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.auth.token,
        cartItems: state.cart.cartItems
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
        fetchCartItems: () => dispatch(fetchCartItems())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ShopTier);