import * as actionTypes from '../actions/actionTypes';
import {updateObject} from "../utility";

const initialState = {
    token: null,
    isAdmin: false,
    authRedirectPath: undefined,
    loading: false,
    errors: {},
    authReady: false
};

const loginStart = (state, action) => {
    return updateObject( state, {
        loading: true
     } );
};

const loginFailure = (state, action) => {
    return updateObject( state, {
        loading: false
     } );
};

const loginSuccess = (state, action) => {
    return updateObject( state, {
        token: action.token,
        isAdmin: action.isAdmin,
        loading: false
     } );
};

const logout = (state, action) => {
    return updateObject(state, { token: null, isAdmin: false});
};

const registerStart = (state, action) => {
    return updateObject( state, {
        loading: true
     } );
};

const registerFailure = (state, action) => {
    return updateObject( state, {
        loading: false,
        errors: action.errors
     } );
};

const registerSuccess = (state, action) => {
    return updateObject( state, {
        loading: false
     } );
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path })
}

const finishauthReady = (state, action) => {
    return {
        ...state,
        authReady: true
    }
}

const clearAuthErrors = (state, action) => {
    return {
        ...state,
        errors: {}
    }
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.LOGIN_START: return loginStart(state, action);
        case actionTypes.LOGIN_FAILURE: return loginFailure(state, action);
        case actionTypes.LOGIN_SUCCESS: return loginSuccess(state, action);
        case actionTypes.REGISTER_START: return registerStart(state, action);
        case actionTypes.REGISTER_FAILURE: return registerFailure(state, action);
        case actionTypes.REGISTER_SUCCESS: return registerSuccess(state, action);
        case actionTypes.LOGOUT: return logout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
        case actionTypes.CLEAR_AUTH_ERRORS: return clearAuthErrors(state, action)
        case actionTypes.FINISH_INITIAL_LOADING: return finishauthReady(state, action)
        default:
            return state;
    }
};

export default reducer;