import {useEffect, useState} from "react";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import getDesignTokens from "./utils/designToken";
import {useNavigate} from "react-router-dom";
import Snack from "./components/Snack";
import {checkAuthState, clearAuthErrors, registerAction, setAuthRedirectPath} from "./store/actions/auth";
import {connect} from "react-redux";

import PrimaryNavigation from "./navigation/primaryNavigation";
import {fetchCartItems} from "./store/actions/cart";

const App = ({authRedirectPath, clearAuthRedirectPath, checkAuthState, isAuthenticated, fetchCartItems}) => {
    const [mode] = useState("dark")
    const theme = createTheme(getDesignTokens(mode))
    const navigate = useNavigate()

    useEffect(() => {
        checkAuthState()
    }, [])

    useEffect(() => {
        if (authRedirectPath) {
            navigate(authRedirectPath)
            clearAuthRedirectPath()
        }
    }, [authRedirectPath]);

    useEffect(() => {
        if (isAuthenticated){
            fetchCartItems()
        }
    }, [isAuthenticated])

    console.log(theme)

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <PrimaryNavigation/>
            <Snack/>
        </ThemeProvider>
    );
}

const mapStateToProps = state => {
    return {
        authRedirectPath: state.auth.authRedirectPath,
        isAuthenticated: !!state.auth.token
    };
};


const mapDispatchToProps = dispatch => {
    return {
        login: (data) => dispatch(registerAction(data)),
        clearAuthErrors: () => dispatch(clearAuthErrors()),
        clearAuthRedirectPath: () => dispatch(setAuthRedirectPath(undefined)),
        checkAuthState: () => dispatch(checkAuthState()),
        fetchCartItems: () => dispatch(fetchCartItems())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);