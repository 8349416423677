import React from 'react';
import {Navigate, Outlet} from "react-router-dom";
import {connect} from "react-redux";
import {routes} from "../../utils/routes";
import CenteredLoader from "../Loaders/CenteredLoader";

const AdminLayout = ({isAdmin, authReady}) => {

    if (!authReady){
        return <CenteredLoader/>
    }

    if (!isAdmin){
        return <Navigate to={routes.homePage} replace/>
    }

    return (
        <Outlet/>
    );
};

const mapStateToProps = state => {
    return {
        isAdmin: !!state.auth.isAdmin,
        authReady: state.auth.authReady
    };
};
export default connect(mapStateToProps)(AdminLayout);