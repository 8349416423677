import React, {useRef, useState} from 'react';
import * as yup from "yup";
import {checkFileSize} from "../../../../../utils/formUtils";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {beatsFilesService} from "../../../../../api/services/beatsFilesService";
import {setSnack} from "../../../../../store/actions/snack";
import {connect} from "react-redux";
import BeatFilesFieldsLayout from "./BeatFilesFieldsLayout";
import {tiers} from "../../../../../utils/tiers";

const schema = yup.object().shape({
    price: yup.number().typeError('Must be a number').required('Required field'),
    file: yup.mixed().test('fileSize', "File is too large", checkFileSize).required("Required Field"),
});


const CreateBeatFile = ({tierId, setSnack, beatId, updateBeatFiles, tierName}) => {
    const freeTier = tierName === tiers.FREE
    const {
        control,
        handleSubmit,
        formState,
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            price: freeTier ? "0" : "",
            file: null
        }
    });

    const [loadingCreateBeatFile, setLoadingCreateBeatFile] = useState(false)

    const fileInput = useRef();

    const onSelectFile = (selectedFile) => {
        // console.log("change file")
        setValue("file", selectedFile.target.files[0], {shouldValidate: true, shouldTouch: true, shouldDirty: true})
    }

    const onSuccess = (data) => {
        setLoadingCreateBeatFile(true)
        beatsFilesService.createBeatFile(beatId, tierId, {...data, price: data.price * 100})
            .then((response) => {
                setSnack(response.message, "success")
                updateBeatFiles({...response.data, price: response.data.price}, tierName)
                reset({price: response.data.price, file: null})
                setLoadingCreateBeatFile(false)
            })
            .catch((errorResponse) => {
                setSnack(errorResponse.message, "error")
                setLoadingCreateBeatFile(false)
            })
    }

    const onError = (errorData) => {

    }

    return (

        <form onSubmit={handleSubmit(onSuccess, onError)}>
            <BeatFilesFieldsLayout
                onSelectFile={onSelectFile}
                freeTier={freeTier}
                fileInput={fileInput}
                loadingSubmit={loadingCreateBeatFile}
                formState={formState}
                reset={reset}
                control={control}
            />
        </form>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity))
    };
};
export default connect(null, mapDispatchToProps)(CreateBeatFile);