import React, {useRef, useState} from 'react';
import {Box, FormHelperText} from "@mui/material";
import {Edit} from "@mui/icons-material";
import {useController, useWatch} from "react-hook-form";
import placeholderImage from "../../../../assets/images/placeholderImage.png"
import CenteredLoader from "../../../Loaders/CenteredLoader";

const SelectBeatImage = ({control, name, setValue, formState, defaultImage = null}) => {
    const [loading, setLoading] = useState(true)
    const image = useWatch({
        control,
        name: "image"
    })

    const {errors} = formState

    const fileInput = useRef();
    const [hoverEdit, setHoverEdit] = useState(false)

    const onSelectFile = (selectedFile) => {
        setValue("image", selectedFile.target.files[0], {shouldValidate: true, shouldTouch: true, shouldDirty: true})
    }

    const errorMessage = errors.image ? errors.image.message : " "

    const onImageError = (e) => {
        e.target.src = placeholderImage
    }

    const onImageLoad = () => {
        setLoading(false)
    }

    return (
        <React.Fragment>
            <Box
                sx={{
                    position: "relative", display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                }}
                onClick={() => fileInput.current.click()}
                onMouseEnter={() => setHoverEdit(true)}
                onMouseLeave={() => setHoverEdit(false)}
            >
                <Box sx={{
                    overflow: "hidden",
                    padding: 2,
                    borderTop: 1,
                    borderLeft: 1,
                    borderRight: 1,
                    borderBottom: 1,
                    borderColor: !!errors.image ? "error.main" : "transparent",
                    borderRadius: 2,
                }}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%);",
                            opacity: hoverEdit ? "100%" : "0%",
                            transition: "opacity 200ms"
                        }}
                    >
                        <Edit fontSize={"large"}/>
                    </Box>
                    <Box
                        component={"img"}
                        src={image ? URL.createObjectURL(image) : !!defaultImage ? defaultImage : placeholderImage}
                        alt="Event Image"
                        sx={{
                            width: 200,
                            height: 200,
                            borderRadius: 2,
                            display: loading ? 'none' : "block"
                        }}
                        onError={onImageError}
                        onLoad={onImageLoad}
                    />
                    <Box
                        sx={{
                            width: 200,
                            height: 200,
                            borderRadius: 2,
                            display: loading ? 'block' : "none"
                        }}
                    >
                        <CenteredLoader/>
                    </Box>

                    <FormHelperText
                        error={true}
                        sx={{
                            ml: 2,
                            visibility: !!errors.image ? "visible" : "hidden",
                            textAlign: "center"
                        }}
                    >
                        {errorMessage}
                    </FormHelperText>
                </Box>

            </Box>
            <input
                style={{display: 'none'}}
                accept="image/*"
                id={`preview-img`}
                type="file"
                onChange={onSelectFile}
                ref={fileInput}
            />
        </React.Fragment>
    );
};

export default SelectBeatImage;