export const LOGIN_START = 'LOGIN_START';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const FINISH_INITIAL_LOADING = 'FINISH_INITIAL_LOADING';

export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS'

export const SET_SNACK = 'SET_SNACK'
export const REMOVE_SNACK = 'REMOVE_SNACK'
export const CART_FETCH_START = 'CART_FETCH_START'
export const CART_FETCH_SUCCESS = 'CART_FETCH_SUCCESS'
export const CART_FETCH_FAILURE = 'CART_FETCH_FAILURE'

export const CART_DELETE_ITEM_START = 'CART_DELETE_ITEM_START'
export const CART_DELETE_ITEM_SUCCESS = 'CART_DELETE_ITEM_SUCCESS'
export const CART_DELETE_ITEM_FAILURE = 'CART_DELETE_ITEM_FAILURE'
