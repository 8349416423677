import React, {useState} from 'react';
import {Button} from "@mui/material";
import {AddShoppingCart, HourglassEmpty} from "@mui/icons-material";
import AddToCartModal from "../Cart/AddToCartModal";
import Tooltip from "@mui/material/Tooltip";

const BeatsAddToCartMobile = ({beat}) => {

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    if (Object.keys(beat.files).length < 1) {
        return (
            <Tooltip title="Coming Soon" placement="top" enterTouchDelay={0}>
                <span>

                <Button
                    fullWidth
                    disabled={true}
                    variant={"contained"}
                >
                    <HourglassEmpty/>
                </Button>
                </span>
            </Tooltip>
        )
    }

    return (
        <AddToCartModal beat={beat} handleClose={handleClose} open={open}>
            <Button
                fullWidth
                color={"secondary"}
                variant={"contained"}
                onClick={() => setOpen(true)}
            >
                <AddShoppingCart/>
            </Button>
        </AddToCartModal>
    );
};
export default BeatsAddToCartMobile;