import React from 'react';
import {useNavigate} from "react-router-dom";
import {Box, IconButton} from "@mui/material";
import CartBadge from "../Cart/Badge";
import {ShoppingCart} from "@mui/icons-material";
import {routes} from "../../utils/routes";
import NavBarUserMenu from "./NavBarUserMenu";
import NavBarCartActionButton from "../Cart/NavBarCartActionButton";

const NavBarAuthActionButtons = () => {

    return (
        <Box>
            <NavBarCartActionButton/>
            <NavBarUserMenu/>
        </Box>
    );
};

export default NavBarAuthActionButtons;