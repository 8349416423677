import React from 'react';
import {Box, Container, Grid, IconButton, Typography} from "@mui/material";
import {Instagram, YouTube} from "@mui/icons-material";
import {openInNewTab} from "../utils/beatsHelpers";

const Socials = () => {
    return (
        <Box sx={{py: 4}}>
            <Typography variant={"h4"} align={"center"} sx={{py: 4}}>
                Socials
            </Typography>
            <Container maxWidth={"xs"}>
                <Grid container alignContent={"center"} textAlign='center'>
                    <Grid item xs={12} md={6} >
                        <IconButton
                            onClick={() => openInNewTab("https://www.instagram.com/yazici.nury/?hl=en")}
                        >
                            <Instagram
                            sx={{
                                fontSize: 150,
                            }}/>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <IconButton
                            onClick={() => openInNewTab("https://www.youtube.com/@light9944")}
                        >
                            <YouTube
                            sx={{
                                fontSize: 150,
                            }}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Socials;