import React from 'react';
import {Container, Grid} from "@mui/material";
import ShopTier from "./ShopTier";
import {cartService} from "../../api/services/cartService";

const ShopTiers = ({beat}) => {
    console.log(beat)

    if (!beat) {
        return null
    }


    return (
        <Container maxWidth={"sm"}>
            <ShopTier
                beatFile={beat.files.free}
                label={"Free"}
                contents={"MP3 and WAV"}
            />
            <ShopTier
                beatFile={beat.files.trackout_lease}
                label={"Trackout Lease"}
                contents={"MP3, WAV and TRACK STEMS"}
            />
            <ShopTier
                beatFile={beat.files.lease}
                label={"Unlimited Lease"}
                contents={"MP3, WAV and TRACK STEMS"}
            />
            <ShopTier
                beatFile={beat.files.exclusive}
                label={"Exclusive"}
                contents={"MP3, WAV and TRACK STEMS"}
            />
        </Container>
    );
};

export default ShopTiers;