import React, {useEffect, useState} from 'react';
import highlightBackground from "../../../assets/images/highlightBackground.jpg";
import {Box, Container, Grid} from "@mui/material";
import SearchSelectField from "./SearchSelectField";
import SearchTextField from "./SearchTextField";


const Search = (
    {
        filters,
        search,
        setSearch,
        bpm,
        setBpm,
        mood,
        setMood,
        genera,
        setGenera,
        artist,
        setArtist,
        changeQueryParams
    }) => {


    return (
        <Box sx={{backgroundImage: `url(${highlightBackground})`, backgroundSize: 'cover', height: {md: 300, xs: 400}}}>
            {filters ?
                <Container maxWidth={"md"} sx={{py: 8}}>
                    <Grid container spacing={2}>
                        <Grid item md={3} xs={6}>
                            <SearchSelectField
                                label={"BPM"}
                                value={bpm}
                                setValue={(event) => changeQueryParams(event.target.value, setBpm)}
                                options={filters.bpm}
                            />
                        </Grid>
                        <Grid item md={3} xs={6}>

                            <SearchSelectField
                                label={"Mood"}
                                value={mood}
                                setValue={(event) => changeQueryParams(event.target.value, setMood)}
                                options={filters.mood}
                            />
                        </Grid>
                        <Grid item md={3} xs={6}>

                            <SearchSelectField
                                label={"Artist"}
                                value={artist}
                                setValue={(event) => changeQueryParams(event.target.value, setArtist)}
                                options={filters.artist}
                            />
                        </Grid>
                        <Grid item md={3} xs={6}>

                            <SearchSelectField
                                label={"Genera"}
                                value={genera}
                                setValue={(event) => changeQueryParams(event.target.value, setGenera)}
                                options={filters.genera}
                            />
                        </Grid>
                        <Grid item md={3}>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <SearchTextField
                                label={"Search"}
                                value={search}
                                setValue={(event) => changeQueryParams(event.target.value, setSearch)}
                            />
                        </Grid>
                        <Grid item md={3}>
                        </Grid>
                    </Grid>
                </Container>
                : null}

        </Box>
    );
};

export default Search;