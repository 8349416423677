import React from 'react';
import {Navigate, Outlet} from "react-router-dom";
import {routes} from "../utils/routes";
import {connect} from "react-redux";

const LoginRequired = ({isAuthenticated, children, authReady}) => {

    if (!authReady) {
        return null
    }

    if (!isAuthenticated) {
        return <Navigate to={routes.homePage} replace/>
    }


    return (
        <>
            {children}
        </>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.auth.token,
        authReady: !!state.auth.authReady,
    };
};
export default connect(mapStateToProps)(LoginRequired);