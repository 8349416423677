import React from 'react';
import {routes} from "../../utils/routes";
import CartBadge from "./Badge";
import {ShoppingCart} from "@mui/icons-material";
import {IconButton} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";

const NavBarCartActionButton = ({cartItems}) => {

    const navigate = useNavigate();
    return (
        <IconButton aria-label="cart" onClick={() => navigate(routes.cart)}>
            <CartBadge badgeContent={cartItems.length} color="secondary">
                <ShoppingCart/>
            </CartBadge>
        </IconButton>
    );
};

const mapStateToProps = state => {
    return {
        cartItems: state.cart.cartItems
    };
};

export default connect(mapStateToProps)(NavBarCartActionButton);