import React, {useState} from 'react';
import Carousel from "../Carousel/Carousel";
import LicenseModal from "../License/LicenseModal";
import {lease} from "../../utils/licenseData/lease";
import {exclusive} from "../../utils/licenseData/exclusive";
import {trackoutLease} from "../../utils/licenseData/trackoutLease";
import {free} from "../../utils/licenseData/free";
import {Box, Typography} from "@mui/material";


const BeatsLicensingCarousel = () => {

    const [selectedLicense, setSelectedLicense] = useState(null)

    const slideData = [
        {
            title: "Free",
            features: [
                "non-commercial use",
                "tagged MP3"
            ],
            license: free
        },
        {
            title: "WAV Trackout Lease",
            features: [
                "Distribution - 10,000 copies",
                "commercial use",
                "(album, mixtape, iTunes, radio, etc)",
                "Trackouts + WAV + MP3",
                "Royalty free"
            ],

            license: trackoutLease
        },
        {
            title: "Unlimited Lease",
            features: [
                "Unlimited Distribution",
                "Unlimited comercial use",
                "(album, mixtape, iTunes, radio, etc)",
                "Trackouts + WAV + MP3",
                "Royalty free"
            ],
            popular: true,
            license: lease
        },
        {
            title: "Exclusive",
            features: [
                // "You get all rights",
                "Contact us for more info"
            ],
            license: exclusive,
            exclusive: true
        },
    ];

    return (
        <Box sx={{my: 4}}>
            <Typography variant={"h4"} align={"center"} sx={{py: 4}}>
                Licensing Info
            </Typography>
            <Carousel
                data={slideData}
                setSelectedLicense={setSelectedLicense}
            />
            <LicenseModal open={!!selectedLicense} licenseData={selectedLicense}
                          handleClose={() => setSelectedLicense(null)}/>
        </Box>
    );
};

export default BeatsLicensingCarousel;