import React from 'react';
import {Box, Card, CardActionArea, Grid, Typography} from "@mui/material";
import placeholderImage from "../../../../assets/images/placeholderImage.png";
import {useNavigate} from "react-router-dom";
import {routes} from "../../../../utils/routes";

const BeatsListItem = ({beatData}) => {

    const navigate = useNavigate()

    const handleClick = () => {
        navigate(routes.adminBeat + `/${beatData.id}`)
    }

    return (
        <Card>
            <CardActionArea onClick={handleClick}>
                <Grid container sx={{p: 1}}>
                    <Grid item xs={3}>
                        <Box
                            component={"img"}
                            src={beatData.aws_image_url ? beatData.aws_image_url : placeholderImage}
                            alt="Event Image"
                            sx={{
                                width: 50,
                                height: 50,
                                borderRadius: 2,
                            }}
                        />
                    </Grid>
                    <Grid item xs={9} container>
                        <Typography variant={"button"} sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            width: '100%',  // You may need to adjust this
                        }}>
                            {beatData.title}
                        </Typography>
                        <Typography color={"text.secondary"} variant={"subtitle2"}>
                            {beatData.artist}
                        </Typography>
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    );
};

export default BeatsListItem;