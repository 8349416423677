const login = "/auth/login"
const homePage = "/"
const register = "/auth/register"
const beats = "/beats"
const beat = "/beat/"
const contact = "/contact"
const about = "/about"
const settings = "/settings"
const adminBeats = "/admin/beats"
const logout = "/auth/logout"
const userBeats = "/user/beats"
const forgotPassword = "/auth/forgotPassword"
const confirmEmail = "/auth/confirmEmail"
const changeForgotPassword = "/auth/changeForgotPassword"
const resendConfirmationEmail = "/auth/resendConfirmationEmail"
const checkout = "/checkout"
const adminBeat = "/admin/beat"
const cart = "/cart"
const orders = "/orders"


export const routes = {
    login,
    register,
    homePage,
    beats,
    settings,
    adminBeats,
    logout,
    userBeats,
    forgotPassword,
    confirmEmail,
    changeForgotPassword,
    resendConfirmationEmail,
    contact,
    about,
    checkout,
    adminBeat,
    beat,
    cart,
    orders
}