import React from 'react';
import SearchEmptyList from "../Beats/Search/SearchEmptyList";
import BeatsListItem from "../Beats/BeatsListItem";
import UserBeatFilesItem from "./UserBeatFilesItem";

const UserBeatFilesList = ({beatFiles}) => {

    if (!beatFiles || beatFiles.length < 1) {
        return <SearchEmptyList/>
    }

    return (
        <>
            {
                beatFiles.map((beatFile, index) => (
                    <UserBeatFilesItem key={index} beatFile={beatFile}/>
                ))
            }
        </>
    );
};

export default UserBeatFilesList;