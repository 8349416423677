import React from 'react';
import moneyBack from "../../assets/icons/moneyBack.png"
import {Box} from "@mui/material";

const MoneyBack = () => {
    return (
        <Box
            sx={{
                position: "relative", display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                py: 2
            }}
        >
            <Box
                component={"img"}
                src={moneyBack}
                alt="Event Image"
                sx={{
                    width: 175,
                    height: 150,
                    borderRadius: 2,
                    margin: "auto"
                }}
            />
        </Box>
    );
};

export default MoneyBack;