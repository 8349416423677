import React from 'react';
import {Box, Typography, Link} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {routes} from "../../../utils/routes";

const LinkToForgotPassword = () => {

    const navigate = useNavigate()

    return (
        <Box sx={{}}>
            <Typography variant={"body1"} align={"right"}>
                <Link
                    component="button"
                    variant="body1"
                    type={"button"}
                    onClick={() => navigate(routes.forgotPassword)}
                >
                   forgot password?
                </Link>
            </Typography>
        </Box>
    );
};

export default LinkToForgotPassword;