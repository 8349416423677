import React from 'react';
import {Box, Grid, Typography, styled} from "@mui/material";

const Line = styled(Box)(({theme}) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: '100%',
    alignSelf: 'center',
}));

const ThirdPartyText = () => {
    return (
        <Box display="flex" alignItems="center" my={2}>
            <Line/>
            <Box mx={2}>
                <Typography variant="body1">or</Typography>
            </Box>
            <Line/>
        </Box>
    );
};

export default ThirdPartyText;