import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {connect} from "react-redux";
import {clearAuthErrors, registerAction} from "../../store/actions/auth";
import AuthLayout from "../../components/Auth/AuthLayout";
import AuthTitle from "../../components/Auth/AuthTitle";
import AuthTextFieldArea from "../../components/Auth/AuthTextFieldArea";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import ControlledTextField from "../../components/ControlledTextField";
import ControlledPasswordField from "../../components/ControlledPasswordField";
import AuthAlternativeActions from "../../components/Auth/AuthAlternativeActions";
import LinkToLogin from "../../components/Auth/Register/LinkToLogin";
import SubmitButton from "../../components/SubmitButton";
import AuthNavigation from "../../components/Auth/AuthNavigation";
import AcceptTermsAndConditions from "../../components/Auth/Register/AcceptTermsAndConditions";
import AuthForm from "../../components/Auth/AuthForm";


const schema = yup.object().shape({
    email: yup.string().email("Must be valid email").required('Required field'),
    password: yup.string().required('Required field').min(8, 'Must be at least 8 characters long'),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match').nullable()
});


const Register = ({register, clearAuthErrors, errors, authLoading}) => {
    const [tosAccepted, setTosAccepted] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    const defaultValues = {
        email: "",
        password: "",
        confirmPassword: ""
    }

    const {
        control,
        handleSubmit,
        setError,
        clearErrors
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues
    });

    const onSuccess = (data) => {
        register(data)
    }

    const onError = (error) => {
        // Handle Errors
        console.log(error)
    }

    useEffect(() => {
        clearAuthErrors()
    }, [clearAuthErrors])

    useEffect(() => {
        for (let fieldName in defaultValues) {
            if (defaultValues.hasOwnProperty(fieldName)) {
                if (errors[fieldName])
                    setError(fieldName, {type: "custom", message: errors[fieldName]})
                else
                    clearErrors(fieldName)
            }
        }
    }, [errors, setError, clearErrors])

    return (
        <AuthLayout>
            <AuthForm onSubmit={handleSubmit(onSuccess, onError)}>
                <AuthTitle title={"Register"}/>
                <AuthTextFieldArea>
                    <ControlledTextField control={control} name={"email"} label={"Email"}/>
                    <ControlledPasswordField control={control} name={"password"} label={"Password"}
                                             setShowPassword={setShowPassword} showPassword={showPassword}/>
                    <ControlledPasswordField control={control} name={"confirmPassword"} label={"Confirm Password"}
                                             setShowPassword={setShowPassword} showPassword={showPassword}/>
                </AuthTextFieldArea>
                <AuthAlternativeActions>
                    <AcceptTermsAndConditions tosAccepted={tosAccepted} setTosAccepted={setTosAccepted}/>
                </AuthAlternativeActions>
                <SubmitButton
                    text={"Register"}
                    disabled={!tosAccepted}
                    loading={authLoading}
                />
            </AuthForm>
            <AuthNavigation>
                <LinkToLogin/>
            </AuthNavigation>
        </AuthLayout>
    )
}

const mapStateToProps = state => {
    return {
        errors: state.auth.errors,
        authLoading: state.auth.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        register: (data) => dispatch(registerAction(data)),
        clearAuthErrors: () => dispatch(clearAuthErrors())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Register);