import React from 'react';
import {Box, Typography} from "@mui/material";
import BrowseAllBeatsButton from "../Beats/BrowseAllBeatsButton";

const EmptyOrders = () => {
    return (
        <Box sx={{p: 10}}>
            <Typography variant={"h4"} align={"center"}>
                You don't have nay orders yet!
            </Typography>
            <BrowseAllBeatsButton/>
        </Box>
    );
};

export default EmptyOrders;