import React from 'react';
import {connect} from "react-redux";
import {
    AppBar,
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton, ListItemText,
    Toolbar
} from "@mui/material";
import {Menu} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {routes} from "../../utils/routes";
import logo from "../../assets/images/logo.png"
import CustomDrawer from "./CustomDrawer";
import CustomAppBar from "./CustomAppBar";

const drawerWidth = 240;

const NavigationBar = ({isAuthenticated, isAdmin, ...props}) => {

    const {window} = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);


    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    let navItems = [
            {
                label: "Home",
                path: routes.homePage
            },
            {
                label: "Beats",
                path: routes.beats
            },
            {
                label: "About",
                path: routes.about
            },
            {
                label: "Contact",
                path: routes.contact
            }
        ];


    return (
        <Box>
            <CustomAppBar handleDrawerToggle={handleDrawerToggle} navItems={navItems} isAuthenticated={isAuthenticated}/>
            <CustomDrawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} navItems={navItems}/>
        </Box>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token,
        isAdmin: state.auth.isAdmin
    };
};

export default connect(mapStateToProps)(NavigationBar);