import * as actionTypes from "./actionTypes";
import {cartService} from "../../api/services/cartService";

const fetchStart = () => {
    return {
        type: actionTypes.CART_FETCH_START
    }
}

const fetchSuccess = (cartItems) => {
    return {
        type: actionTypes.CART_FETCH_SUCCESS,
        cartItems
    }
}

const fetchFailure= (errorMessage) => {
    return {
        type: actionTypes.CART_FETCH_FAILURE,
        errorMessage
    }
}

export const fetchCartItems = () => {
    return (dispatch) => {
        dispatch(fetchStart())
        cartService.fetchCartItems()
            .then((response) => {
                dispatch(fetchSuccess(response.data))
            })
            .catch((errorResponse) => {
                dispatch(fetchFailure(errorResponse.message))
            })
    }
}