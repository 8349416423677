import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {routes} from "../utils/routes";
import AuthNavigation from "./authNavigation";
import AppNavigation from "./appNavigation";

const PrimaryNavigation = () => {
    return (
            <Routes>
                <Route path="/auth/*" element={<AuthNavigation/>}/>
                <Route path="/*" element={<AppNavigation/>}/>
                <Route
                    path="*"
                    element={<Navigate to={routes.homePage} replace/>}
                />
            </Routes>
    );
};

export default PrimaryNavigation;