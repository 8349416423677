import React, {useState} from 'react';
import {Box, Grid, Typography} from "@mui/material";
import * as yup from "yup";
import {checkSize} from "../../../../../utils/formUtils";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import CreateBeatFile from "./CreateBeatFile";
import UpdateBeatFile from "./UpdateBeatFile";


const BeatFile = ({data, title, tierId, tierName, beatId, updateBeatFiles}) => {

    const beatFile = data[tierName]

    let fieldsElement = <CreateBeatFile
        tierId={tierId}
        beatId={beatId}
        updateBeatFiles={updateBeatFiles}
        tierName={tierName}
    />

    if (!!beatFile) {
        fieldsElement = <UpdateBeatFile
            beatFile={beatFile}
            tierId={tierId}
            beatId={beatId}
            updateBeatFiles={updateBeatFiles}
            tierName={tierName}
        />
    }

    return (
        <Grid container sx={{my: 1}} spacing={2}>
            <Grid item xs={12}>
                <Typography variant={"button"}>
                    {title}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {fieldsElement}
            </Grid>
        </Grid>
    );
};

export default BeatFile;