import React from 'react';
import {Container, Typography, Box, Link, Grid} from '@mui/material';

const Contact = () => {

    return (
        <Container maxWidth="sm">
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant={"h4"} align={"center"} sx={{
                        py: 3
                    }}>
                        Contact
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography align={"center"}>
                        For any business inquiries, collaborations, or issues you might encounter while using our
                        services, please feel free to reach out to us. YaziciNuryBeats is dedicated to creating
                        beautiful music and values the relationship with all clients and users. We aim to address all
                        queries promptly and professionally. Your feedback helps us improve and deliver a better
                        experience. Thank you for choosing us as your music partner.
                    </Typography>
                </Grid>
                                <Grid item xs={12}>
                    <Typography variant={"h4"} align={"center"} sx={{
                        py: 3
                    }}>
                        Email
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography align={"center"} variant={"h5"}>
                        admin@yazicinurybeats.com
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Contact;