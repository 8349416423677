import React from 'react';
import {Grid, IconButton, Typography} from "@mui/material";
import {ArrowBackIosNew} from "@mui/icons-material";
import BackButton from "../BackButton";
import {routes} from "../../utils/routes";

const AuthTitle = ({title, backRoute=routes.homePage}) => {
    return (
        <Grid container alignContent={"center"}>
            <Grid item xs={2}>
                <BackButton route={backRoute}/>
            </Grid>
            <Grid item xs={10}>
                <Typography variant={"h4"} gutterBottom={false}>
                    {title}
                </Typography>
            </Grid>
        </Grid>
    )
};

export default AuthTitle;