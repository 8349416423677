import React from 'react';
import {Box, Typography, Link} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {routes} from "../../../utils/routes";

const LinkToLogin = () => {

    const navigate = useNavigate()

    return (
        <Box sx={{}}>
            <Typography variant={"body1"} align={"center"}>
                Already have an account?&nbsp;
                <Link
                    component="button"
                    variant="body1"
                    onClick={() => navigate(routes.login)}
                >
                    Login Now
                </Link>
            </Typography>
        </Box>
    );
};

export default LinkToLogin;