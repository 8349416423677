import React, {useState} from 'react';
import {connect} from "react-redux";
import {Box, Divider, Grid, Typography} from "@mui/material";
import CheckoutListItem from "./CartListItem";
import LicenseModal from "../License/LicenseModal";

const CartList = ({cartItems}) => {

    const [selectedLicense, setSelectedLicense] = useState(null)

    return (
        <>
            <Grid container spacing={2}>
                <Grid container item xs={12} sx={{
                display: {xs: "none", md: "flex"}
            }}>
                    <Grid item md={2}>

                    </Grid>
                    <Grid item xs={6} md={5}>
                        <Typography>
                            Beat
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={1}>
                        <Typography align={"right"}>
                            Price
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {cartItems.map((cartItem, index) => {
                    return <CheckoutListItem key={index} cartItem={cartItem} setSelectedLicense={setSelectedLicense}/>
                })}

                <Grid item xs={12}>
                    <Divider/>
                </Grid>
            </Grid>
            <LicenseModal open={!!selectedLicense} licenseData={selectedLicense}
                          handleClose={() => setSelectedLicense(null)}/>
        </>
    );
};


export default CartList;