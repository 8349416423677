import React from 'react';
import {Box, Typography} from "@mui/material";

const ResendConfirmationEmailHelperText = ({email}) => {
    return (
        <>

            <Typography variant={"body1"} align={"center"}>
                We sent a confirmation email to:
            </Typography>
            <Typography variant={"body1"} sx={{my: 2}} align={"center"}><b>{email}</b></Typography>

            <Typography variant={"body1"} align={"center"}>
                Check your email and click on the confirmation link to continue
            </Typography>
        </>
    );
};

export default ResendConfirmationEmailHelperText;