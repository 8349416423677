export const format = (value, pattern) => {
    let i = 0;
    const v = value.toString();
    return pattern.replace(/#/g, _ => v[i++]);
}

export const formatDecimal = (value) => {
    const strValue = value.toString();
    const length = strValue.length;
    return length > 2 ? strValue.slice(0, -2) + "." + strValue.slice(-2) : "0." + strValue.padStart(2, "0");
}

export const formatLeadingZeros = (number) => {
  // Convert the number to a string
  let numberString = number.toString();

  // Calculate the number of leading zeros needed
  let zerosNeeded = 6 - numberString.length;

  // Add leading zeros if necessary
  for (let i = 0; i < zerosNeeded; i++) {
    numberString = '0' + numberString;
  }

  return numberString;
}