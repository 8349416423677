import React from 'react';
import {IconButton} from "@mui/material";
import {ArrowBackIosNew} from "@mui/icons-material";
import {useNavigate} from 'react-router-dom';

const BackButton = ({route}) => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(route)
    };


    return (
        <IconButton aria-label="delete" onClick={goBack}>
            <ArrowBackIosNew/>
        </IconButton>
    );
};

export default BackButton;