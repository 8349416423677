import React from 'react';
import {Button} from "@mui/material";
import {openInNewTab} from "../../utils/beatsHelpers";

const RetryCheckoutButton = ({order}) => {

    const onSubmit = () => {
         window.location.replace(order.checkout_url)
    }

    return (
        <Button fullWidth variant={"contained"} onClick={() => onSubmit()}>
            Retry checkout
        </Button>
    );
};

export default RetryCheckoutButton;