import React from 'react';
import {TextField} from "@mui/material";

const SearchTextField = ({value, setValue, label, sx={}}) => {
    return (
        <TextField
            label={label}
            value={value}
            fullWidth
            variant={"standard"}
            onChange={setValue}
            sx={sx}
        />
    );
};

export default SearchTextField;