import React, {useEffect, useState} from 'react';
import {beatsService} from "../api/services/beatsService";
import {useParams} from "react-router-dom";
import {setSnack} from "../store/actions/snack";
import {connect} from "react-redux";
import HighlightedBeat from "../components/Beats/HighlightedBeat";
import ShopTiers from "../components/Beat/ShopTiers";
import BeatsList from "../components/Beats/BeatsList";
import BrowseAllBeatsButton from "../components/Beats/BrowseAllBeatsButton";

const Beat = ({setSnack}) => {

    const {beatId} = useParams();

    const [loadingBeat, setLoadingBeat] = useState(true)
    const [beat, setBeat] = useState(null)

    const [loadingBeats, setLoadingBeats] = useState(true)
    const [beats, setBeats] = useState([])

    useEffect(() => {
        beatsService.fetchBeat(beatId)
            .then((response) => {
                setLoadingBeat(false)
                setBeat(response.data)
            })
            .catch((errorResponse) => {
                setLoadingBeat(false)
                setSnack(errorResponse.message, "error")
            })
    }, [beatId])


    useEffect(() => {
        if (beat) {
            beatsService.fetchBeats({page: 1, perPage: 10, artist: beat.artist})
                .then((response) => {
                    setBeats(response.data.beats)
                    setLoadingBeats(false)
                })
                .catch((errorResponse) => {
                    console.log(errorResponse.message)
                    setLoadingBeats(false)
                })
        }
    }, [beat])


    return (
        <>
            <HighlightedBeat beat={beat} disableShop={true}/>
            <ShopTiers beat={beat}/>
            <BeatsList beats={beats.filter((beat) => beat.id !== parseInt(beatId))}/>
            <BrowseAllBeatsButton/>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity))
    };
};
export default connect(null, mapDispatchToProps)(Beat);