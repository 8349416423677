import React, {useEffect} from 'react';
import {Box, CircularProgress} from "@mui/material";
import {Navigate, useNavigate, useSearchParams} from "react-router-dom";
import {routes} from "../../utils/routes";
import {authService} from "../../api/services/authService";
import {clearAuthErrors, registerAction, setAuthRedirectPath} from "../../store/actions/auth";
import {connect} from "react-redux";
import {setSnack} from "../../store/actions/snack";
import FullPageLoader from "../../components/Loaders/FullPageLoader";

const ConfirmEmail = ({setSnack, setAuthRedirectPath}) => {
    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams();

    const confirmationCode = searchParams.get("confirmation_code")

    useEffect(() => {
        console.log(confirmationCode)
        if (confirmationCode) {
            authService.confirmEmail(confirmationCode)
                .then((response) => {
                    setSnack(response.message, "success")
                    setAuthRedirectPath(routes.login)
                })
                .catch((error) => {
                    setSnack(error.message, "error")
                    setAuthRedirectPath(routes.login)
                })
        }
    }, [])

    if (!confirmationCode) {
        return <Navigate to={routes.login} replace={true}/>
    }

    return <FullPageLoader/>
};


const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
        setAuthRedirectPath: (path) => dispatch(setAuthRedirectPath(path))
    };
};
export default connect(null, mapDispatchToProps)(ConfirmEmail);