import React from 'react';
import {Grid} from "@mui/material";

const AuthTextFieldArea = ({children}) => {
    return (
        <Grid container spacing={2} sx={{pt: 3}}>
            {
                Array.isArray(children) ?

                    children.map((textField, index) => (
                        <Grid item xs={12} key={index}>
                            {textField}
                        </Grid>
                    )) :

                    <Grid item xs={12}>
                        {children}
                    </Grid>
            }
        </Grid>
    );
};

export default AuthTextFieldArea;