import React, {useState} from 'react';
import {Button, CircularProgress, Grid, IconButton, Paper, Typography} from "@mui/material";
import {Download} from "@mui/icons-material";
import {contents, licensesData} from "../../utils/tiers";
import {beatsFilesService} from "../../api/services/beatsFilesService";
import {setSnack} from "../../store/actions/snack";
import {connect} from "react-redux";
import CenteredLoader from "../Loaders/CenteredLoader";

const UserBeatFilesItem = ({beatFile, setSnack}) => {

    const [loading, setLoading] = useState(false)

    const onDownload = () => {
        setLoading(true)
        beatsFilesService.downloadUserBeatFile(beatFile.id)
            .then((response) => {
                const contentDisposition = response.headers['content-disposition'];
                let filename = 'default_filename.zip'; // Default filename in case the header is not set

                if (contentDisposition) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(contentDisposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }

                const link = document.createElement('a');
                const url = URL.createObjectURL(response.data) //<---- this should be data.data
                link.download = filename;
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                setLoading(false)
            })
            .catch((errorResponse) => {
                setLoading(false)
                setSnack(errorResponse.message, "error")
            })
    }

    return (
        <Paper>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    p: 2,
                    my: 2
                }}
            >
                <Grid item container xs={8} spacing={2}>
                    <Grid item xs={12}>

                        <Typography
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '100%',
                                fontWeight: "bold"
                            }}
                            variant={"h5"}
                        >
                            {beatFile.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography color={"text.secondary"}>
                            {contents[beatFile.beat_file.tier.name]}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={"auto"}>
                    {
                        loading ?
                                <CenteredLoader size={24}/> :

                            <Button
                                onClick={() => onDownload()}
                                variant={"contained"}
                                color={"secondary"} fullWidth
                                // onClick={() => console.log("download")}
                                sx={{mt: {xs: 2, sm: 0}}}
                            >
                                Download
                            </Button>
                    }
                </Grid>
            </Grid>
        </Paper>

    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity))
    };
};
export default connect(null, mapDispatchToProps)(UserBeatFilesItem);