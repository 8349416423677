import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {connect} from "react-redux";
import {clearAuthErrors, registerAction} from "../../store/actions/auth";
import AuthLayout from "../../components/Auth/AuthLayout";
import AuthTitle from "../../components/Auth/AuthTitle";
import AuthTextFieldArea from "../../components/Auth/AuthTextFieldArea";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import ControlledTextField from "../../components/ControlledTextField";
import ControlledPasswordField from "../../components/ControlledPasswordField";
import AuthAlternativeActions from "../../components/Auth/AuthAlternativeActions";
import LinkToLogin from "../../components/Auth/Register/LinkToLogin";
import SubmitButton from "../../components/SubmitButton";
import AuthNavigation from "../../components/Auth/AuthNavigation";
import AcceptTermsAndConditions from "../../components/Auth/Register/AcceptTermsAndConditions";
import {authService} from "../../api/services/authService";
import {routes} from "../../utils/routes";
import {setSnack} from "../../store/actions/snack";
import {Navigate, useSearchParams} from "react-router-dom";
import ResendConfirmationEmailHelperText
    from "../../components/Auth/ResendConfirmationEmail/ResendConfirmationEmailHelperText";

const ResendConfirmationEmail = ({setSnack}) => {
    const [loading, setLoading] = useState(false)

    let [searchParams] = useSearchParams();
    const email = searchParams.get("email")

    const onSubmit = (data) => {

        setLoading(true)

        authService.resendConfirmationEmail(email)
                .then((response) => {
                    setSnack(response.message, "success")
                    setLoading(false)
                })
                .catch((error) => {
                    setSnack(error.message, "error")
                    setLoading(false)
                })
    }

    if (!email) {
        return <Navigate to={routes.login} replace={true}/>
    }

    return (
        <AuthLayout>
            <AuthTitle title={"Confirm Email"} backRoute={routes.login}/>
            <AuthAlternativeActions>
                <ResendConfirmationEmailHelperText email={email}/>
            </AuthAlternativeActions>
            <SubmitButton
                text={"Resend email"}
                onSubmit={onSubmit}
                loading={loading}
            />
        </AuthLayout>
    )
}

const mapStateToProps = state => {
    return {
        errors: state.auth.errors,
        authLoading: state.auth.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
        clearAuthErrors: () => dispatch(clearAuthErrors())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResendConfirmationEmail);