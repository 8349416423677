import React from 'react';
import {Button, CircularProgress} from "@mui/material";
import {AddShoppingCart, Download} from "@mui/icons-material";
import {formatDecimal} from "../../utils/formatText";

const AddToCartButtonWithPrice = ({price, onAddToCart, disabled, loading, alreadyPurchased}) => {
    const free = !price

    let text = "FREE"
    let icon = <Download/>

    if (disabled) {
        text = "unavailable"
        icon = null
    }
    if(loading) {
        icon = null
    }
    if (alreadyPurchased){
        text = "In cart"
        icon = null
    }
    else if (!free) {
        text =  "$" + formatDecimal(price)
        icon = <AddShoppingCart/>
    }

    return (
        <Button
            fullWidth
            color={"secondary"}
            variant={"contained"}
            endIcon={icon}
            onClick={() => onAddToCart()}
            disabled={disabled || loading || alreadyPurchased}
        >
            {loading ? <>
                    <CircularProgress size={24}/>
                    &nbsp;
                </>
                : text}
        </Button>
    );
};

export default AddToCartButtonWithPrice;