import React, {useEffect, useState} from 'react';
import LoginRequired from "../../components/LoginRequired";
import {setSnack} from "../../store/actions/snack";
import {connect} from "react-redux";
import {beatsService} from "../../api/services/beatsService";
import {beatsFilesService} from "../../api/services/beatsFilesService";
import SearchTextField from "../../components/Beats/Search/SearchTextField";
import UserBeatFilesList from "../../components/UserBeatFiles/UserBeatFilesList";
import {Container, Grid} from "@mui/material";
import TriggerOnScrollEnd from "../../components/TriggerOnScrollEnd";

const UserBeats = ({setSnack}) => {

    const [beatFiles, setBeatFiles] = useState([])
    const [loadingBeatFiles, setLoadingBeatFiles] = useState(true)

    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)

    const [search, setSearch] = useState("")


    useEffect(() => {
        setLoadingBeatFiles(true)
        beatsFilesService.fetchUserBeatFiles(
            search,
            page
        )
            .then((response) => {
                setLoadingBeatFiles(false)
                if (page === 1)
                    setBeatFiles(response.data.order_files)
                else
                    setBeatFiles(prevBeats => [...prevBeats, ...response.data.order_files])
                setMaxPage(response.data.pages)
            })
            .catch((errorResponse) => {
                setLoadingBeatFiles(false)
                setSnack(errorResponse.message, "error")
            })
    }, [page, search])


    const changeSearchQuery = (newValue) => {
        setSearch(newValue)
        setPage(1)
    }

    const fetchMoreBeats = () => {
        if (page < maxPage)
            setPage(page + 1)
    }

    return (
        <TriggerOnScrollEnd maxHeight={"88vh"} onTarget={() => fetchMoreBeats()}>
            <Container maxWidth={"sm"}>
                <Grid container>
                    <Grid item xs={12}>
                        <SearchTextField
                            label={"Search"}
                            setValue={(event) => changeSearchQuery(event.target.value)}
                            value={search}
                            sx={{my: 2}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <UserBeatFilesList beatFiles={beatFiles}/>
                    </Grid>
                </Grid>

            </Container>
        </TriggerOnScrollEnd>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity))
    };
};
export default connect(null, mapDispatchToProps)(UserBeats);