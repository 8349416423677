import React from 'react';
import {Button, Grid} from "@mui/material";
import SubmitButton from "../../../SubmitButton";
import ButtonWithConfirmation from "../../../ButtonWithConfirmation";

const BeatActionButtons = (
    {
        loadingUpdateBeat,
        updateBeatDisabled,
        onReset,
        resetDisabled,
        onDelete,
        loadingDeleteBeat,
        onNavigateToFiles
    }) => {
    return (
        <Grid container spacing={1}>
            <Grid item container spacing={2} xs={12} md={3}>
                <Grid item xs={12}>
                    <ButtonWithConfirmation text={"delete"} onConfirm={onDelete} loading={loadingDeleteBeat}/>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant={"outlined"}
                        color={"warning"}
                        fullWidth
                        onClick={() => onReset()}
                        disabled={resetDisabled}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>

            <Grid item xs={0} md={6}>

            </Grid>
            <Grid item container spacing={2} xs={12} md={3}>
                <Grid item xs={12}>
                    <Button
                        variant={"contained"}
                        fullWidth
                        onClick={onNavigateToFiles}
                    >
                        Files
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <SubmitButton
                        text={"save"}
                        loading={loadingUpdateBeat}
                        color={"success"}
                        disabled={updateBeatDisabled}
                    />
                </Grid>
            </Grid>

        </Grid>
    );
};

export default BeatActionButtons;