import buildClient from "../buildClient";
import {endpoints} from "../endpoints";

const fetchBeats = ({title = "", bpm = "", artist = "", genera = "", mood = "", page = 1, perPage = 10}) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            title,
            bpm,
            artist,
            genera,
            mood,
            page,
            per_page: perPage
        }

        apiClient.get(endpoints.beatsList, {params: data})
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const createBeat = ({
                        title,
                        bpm,
                        artist,
                        genera,
                        image,
                        duration,
                        mood,
                        previewUrl
                    }) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const formData = new FormData();

        formData.append("title", title)
        formData.append("mood", mood)
        formData.append("bpm", bpm)
        formData.append("artist", artist)
        formData.append("duration", duration)
        formData.append("genera", genera)
        formData.append("preview_url", previewUrl)
        formData.append('image', image, image.name);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }


        apiClient.post(endpoints.beatsList, formData, config)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const fetchBeat = (beatId) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.get(endpoints.beatsList + `/${beatId}`)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const deleteBeat = (beatId) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.delete(endpoints.beatsList + `/${beatId}`)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const updateBeat = (beatId, beatData) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const formData = new FormData();

        if (beatData.title)
            formData.append("title", beatData.title)
        if (beatData.mood)
            formData.append("mood", beatData.mood)
        if (beatData.bpm)
            formData.append("bpm", beatData.bpm)
        if (beatData.artist)
            formData.append("artist", beatData.artist)
        if (beatData.duration)
            formData.append("duration", beatData.duration)
        if (beatData.genera)
            formData.append("genera", beatData.genera)
        if (beatData.previewUrl)
            formData.append("preview_url", beatData.previewUrl)
        if (beatData.image)
            formData.append('image', beatData.image, beatData.image.name);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        apiClient.put(endpoints.beatsList + `/${beatId}`, formData, config)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

const fetchFilters = () => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.get(endpoints.beatsFilters)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}



export const beatsService = {
    fetchBeats,
    createBeat,
    fetchBeat,
    updateBeat,
    deleteBeat,
    fetchFilters
}