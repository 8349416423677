import React, {useEffect, useMemo, useState} from 'react';
import {CircularProgress, Container, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import * as yup from "yup";
import {checkFormat, checkSize} from "../../utils/formUtils";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {beatsService} from "../../api/services/beatsService";
import {setSnack} from "../../store/actions/snack";
import {connect} from "react-redux";
import {routes} from "../../utils/routes";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import SelectBeatImage from "../../components/Admin/Beats/Beat/SelectBeatImage";
import BeatTextFieldArea from "../../components/Admin/Beats/Beat/BeatTextFieldArea";
import ControlledTextField from "../../components/ControlledTextField";
import BeatActionButtons from "../../components/Admin/Beats/Beat/BeatActionButtons";
import BeatTextFields from "../../components/Admin/Beats/Beat/BeatTextFields";
import AdminBeatLayout from "../../components/Admin/Beats/AdminBeatLayout";

const schema = yup.object().shape({
    title: yup.string().required('Required field'),
    artist: yup.string().required('Required field'),
    mood: yup.string().required('Required field'),
    bpm: yup.string().required('Required field'),
    genera: yup.string().required('Required field'),
    previewUrl: yup.string().required('Required field'),
    duration: yup.string().required('Required field'),
    image: yup.mixed().test('fileSize', "File is too large", checkSize).test('fileType', "Invalid image format", checkFormat),
});

const AdminEditBeat = ({setSnack}) => {
    const {beatId} = useParams();
    const [beat, setBeat] = useState(null)
    const [loadingBeat, setLoadingBeat] = useState(true)
    const [loadingUpdateBeat, setLoadingUpdateBeat] = useState(false)
    const [loadingDeleteBeat, setLoadingDeleteBeat] = useState(false)

    const navigate = useNavigate()

    const defaultValues = {
        title: "",
        mood: "",
        bpm: "",
        artist: "",
        previewUrl: "",
        duration: "",
        genera: "",
    }

    const {
        control,
        handleSubmit,
        setValue,
        formState,
        reset,
        getFieldState,
        setError,
        clearErrors
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues
    });

    const resetFields = (data) => {
        reset({
            title: data.title,
            mood: data.mood,
            bpm: data.bpm,
            artist: data.artist,
            previewUrl: data.preview_url,
            duration: data.duration,
            genera: data.genera,
        })
    }

    useEffect(() => {
        const handlePopState = () => {
            navigate(routes.adminBeats);
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    useEffect(() => {
        beatsService.fetchBeat(beatId)
            .then((response) => {
                if (!response.data)
                    navigate(routes.adminBeats)
                setBeat(response.data)
                resetFields(response.data)
                setLoadingBeat(false)
            })
            .catch((errorResponse) => {
                setSnack(errorResponse.message, "error")
                navigate(routes.adminBeats)
            })
    }, [])

    const onSuccess = (data) => {
        const fieldsToUpdate = Object.fromEntries(
            Object.entries(data).filter(([key, value]) => getFieldState(key).isDirty))

        setLoadingUpdateBeat(true)
        beatsService.updateBeat(beatId, fieldsToUpdate)
            .then((response) => {
                setLoadingUpdateBeat(false)
                setBeat(response.data)
                resetFields(response.data)
                setSnack(response.message, "success")
            })
            .catch((errorResponse) => {
                setLoadingUpdateBeat(false)
                if (typeof errorResponse.message === 'object') {
                    for (let fieldName in defaultValues) {
                        if (defaultValues.hasOwnProperty(fieldName)) {
                            if (!!errorResponse.message[fieldName]) {
                                setError(fieldName, {type: "custom", message: errorResponse.message[fieldName]})
                            } else {
                                clearErrors(fieldName)
                            }
                        }

                    }
                } else {
                    setSnack(errorResponse.message, "error")
                }
            })
    }

    const onError = (errorData) => {
    }

    const onReset = () => {
        reset()
    }

    const onDelete = () => {
        setLoadingDeleteBeat(true)
        beatsService.deleteBeat(beatId)
            .then((response) => {
                setSnack(response.message, "success")
                setLoadingDeleteBeat(false)
                navigate(routes.adminBeats)
            })
            .catch((errorResponse) => {
                setSnack(errorResponse.message, "error")
                setLoadingDeleteBeat(false)
            })
    }

    const onNavigateToFiles = () => {
        navigate(routes.adminBeat + `/${beatId}/files`)
    }

    if (loadingBeat)
        return <FullPageLoader/>

    return (
        <AdminBeatLayout>
            <form onSubmit={handleSubmit(onSuccess, onError)}>
                <SelectBeatImage control={control} name={"image"} setValue={setValue} formState={formState}
                                 defaultImage={beat.aws_image_url}/>

                <BeatTextFields control={control}/>
                <BeatActionButtons
                    loadingUpdateBeat={loadingUpdateBeat}
                    updateBeatDisabled={!formState.isDirty}
                    onReset={onReset}
                    resetDisabled={!formState.isDirty}
                    onDelete={onDelete}
                    loadingDeleteBeat={loadingDeleteBeat}
                    onNavigateToFiles={onNavigateToFiles}
                />
            </form>
        </AdminBeatLayout>
    )
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity))
    };
};
export default connect(null, mapDispatchToProps)(AdminEditBeat);