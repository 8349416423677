import React from 'react';
import {Box, Button, Chip, Container, Grid, Typography, Link} from "@mui/material";
import placeholderImage from "../../assets/images/placeholderImage.png";
import {Edit, PlayArrow, PlayCircle} from "@mui/icons-material";
import highlightBackground from "../../assets/images/highlightBackground.jpg"
import {format} from "../../utils/formatText";
import {AddShoppingCart} from "@mui/icons-material";
import {openInNewTab} from "../../utils/beatsHelpers";
import BeatMoodChip from "./BeatMoodChip";
import BeatGeneraChip from "./BeatGeneraChip";
import BeatsAddToCartButton from "./BeatsAddToCartButton";
import {routes} from "../../utils/routes";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import BeatTitleLink from "./BeatTitleLink";

const HighlightedBeat = ({beat = null, disableShop = false}) => {
    const navigate = useNavigate()
    return (
        <Box sx={{backgroundImage: `url(${highlightBackground})`, backgroundSize: 'cover', height: {md: 300, xs: 400}}}>
            {
                beat ?
                    <Container maxWidth={"md"} sx={{py: 10}}>
                        <Grid container alignItems={"center"}>
                            <Grid item xs={12} md={4}>
                                <Box
                                    sx={{
                                        position: "relative", display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Box
                                        component={"img"}
                                        src={beat.aws_image_url}
                                        alt="Event Image"
                                        sx={{
                                            width: 150,
                                            height: 150,
                                            borderRadius: 2,
                                            margin: "auto"
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: "translate(-50%, -50%);",
                                            transition: "opacity 200ms"
                                        }}
                                    >
                                        <PlayCircle
                                            sx={{
                                                fontSize: 40,
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    color: 'text.secondary',
                                                },
                                            }}
                                            onClick={() => openInNewTab(beat.preview_url)}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item container xs={12} md={8} spacing={1} sx={{pt: {xs: 2, md: 0}}}>
                                <Grid item xs={12} md={12} container spacing={2}
                                      justifyContent={{xs: "center", md: "left"}}
                                >
                                    <Grid item>
                                        <Typography variant={"body1"}>
                                            {beat.artist}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"body1"} color={"text.secondary"}>
                                            &#183;
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"body1"} color={"text.secondary"}>
                                            {beat.bpm} bpm
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"body1"} color={"text.secondary"}>
                                            &#183;
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"body1"} color={"text.secondary"}>
                                            {format(beat.duration, "#:##")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography
                                        variant={"h4"}
                                        // align={"center"}
                                        sx={{
                                            textAlign: {xs: "center", md: "left"},
                                            fontWeight: 'bold',
                                        }}
                                    >   { disableShop ? beat.title : <BeatTitleLink beat={beat}/>}

                                    </Typography>
                                </Grid>
                                <Grid item container md={12} alignItems={"center"} spacing={1}
                                      justifyContent={{xs: "center", md: "left"}}>
                                    {!disableShop ? <Grid item md={4} >
                                        <BeatsAddToCartButton beat={beat}/>
                                    </Grid> : null }
                                    <Grid item>
                                        <BeatMoodChip mood={beat.mood}/>
                                    </Grid>
                                    <Grid item>
                                        <BeatGeneraChip genera={beat.genera}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container> : null
            }
        </Box>

    );
};

export default HighlightedBeat;