import React from 'react';
import {Box, Button, Chip, Grid, Typography} from "@mui/material";
import {AddShoppingCart, PlayCircle} from "@mui/icons-material";
import {openInNewTab} from "../../utils/beatsHelpers";
import BeatMoodChip from "./BeatMoodChip";
import BeatGeneraChip from "./BeatGeneraChip";
import BeatsAddToCartButton from "./BeatsAddToCartButton";
import BeatsAddToCartMobile from "./BeatsAddToCartMobile";
import {format} from "../../utils/formatText";
import BeatTitleLink from "./BeatTitleLink";

const BeatsListItem = ({beat}) => {
    return (
        <Grid item xs={12} container alignItems={"center"} spacing={2}>
            <Grid item xs={2} md={1}>
                <Box
                    sx={{
                        position: "relative", display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        component={"img"}
                        src={beat.aws_image_url}
                        alt="Event Image"
                        sx={{
                            width: 50,
                            height: 50,
                            borderRadius: 2,
                            margin: "auto",
                            cursor: 'pointer'
                        }}
                        onClick={() => openInNewTab(beat.preview_url)}
                    />
                </Box>
            </Grid>
            <Grid item xs={7} md={4}>
                <Typography sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%',
                }}>
                    <BeatTitleLink beat={beat}/>
                </Typography>
            </Grid>
            <Grid item md={1} sx={{display: {xs: "none", md: "block"}}}>
                <Typography>
                      {format(beat.duration, "#:##")}
                </Typography>
            </Grid>
            <Grid item md={1} sx={{display: {xs: "none", md: "block"}}}>
                <Typography>
                    {beat.bpm}
                </Typography>
            </Grid>
            <Grid item md={3} sx={{display: {xs: "none", md: "block"}}}>
                <Grid container alignItems={"center"} spacing={1}
                      justifyContent={{xs: "center", md: "left"}}>
                    <Grid item>
                        <BeatMoodChip mood={beat.mood}/>
                    </Grid>
                    <Grid item>
                        <BeatGeneraChip genera={beat.genera}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={2} sx={{display: {xs: "none", md: "block"}}}>
                <BeatsAddToCartButton beat={beat}/>
            </Grid>
            <Grid item xs={3} md={2} sx={{display: {xs: "block", md: "none"}}}>
                <BeatsAddToCartMobile beat={beat}/>
            </Grid>
        </Grid>
    );
};

export default BeatsListItem;