import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import authReducer from "./reducers/auth";
import snackReducer from "./reducers/snack";
import cartReducer from "./reducers/cart";
import thunk from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    snack: snackReducer,
    cart: cartReducer
});

export const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));
