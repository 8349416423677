import React from 'react';
import AppLayout from "../components/AppLayout";
import {Box, Container, Grid, Typography} from "@mui/material";
import {openInNewTab} from "../utils/beatsHelpers";
import AboutImg from "../assets/images/about.jpg"
import Socials from "../components/Socials";

const About = () => {
    return (
        <Container maxWidth={"sm"}>

            <Grid container spacing={2} sx={{
                py: 3
            }}>
                <Grid item xs={12}>
                    <Typography variant={"h4"} align={"center"} sx={{
                        py: 3
                    }}>
                        About
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            position: "relative", display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                        }}
                    >
                        <Box
                            component={"img"}
                            src={AboutImg}
                            alt="Event Image"
                            sx={{
                                width: 200,
                                height: 200,
                                borderRadius: 100,
                                margin: "auto",
                                cursor: 'pointer'
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography align={"center"} variant={"h5"} fontWidth={"bold"}>
                        Yazici Nury (Light)
                    </Typography>
                </Grid>
                <Grid item xs={12}>

                    <Socials/>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={12}>

                        <Typography align={"center"} variant={"h4"} fontWidth={"bold"}>
                            Biography
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>

                    <Typography variant={"body1"} align={"center"}>
                        Welcome to the official website of YaziciNuryBeats, a dynamic and innovative music producer who
                        is leaving an indelible mark on the modern music scene. Known for his ability to weave together
                        complex rhythms, haunting soundscapes, and compelling melodies, YaziciNuryBeats has a unique
                        voice that echoes through every track he produces. His work, while diverse, consistently carries
                        a distinct edge that subtly nods to the darker and more intense corners of music.

                    </Typography>
                </Grid>
                <Grid item xs={12}>

                    <Typography variant={"body1"} align={"center"}>
                        Behind the name YaziciNuryBeats is a seasoned maestro with years of experience in the music
                        industry. His profound understanding of music theory and production techniques, combined with a
                        natural affinity for intensity and depth, results in music that resonates with listeners on many
                        levels. From the subtly grim tonality that underpins his creations, to the energetic and
                        uncompromising rhythms that drive them, YaziciNuryBeats' portfolio is a testament to his
                        versatility and artistic depth.
                    </Typography>
                </Grid>
                <Grid item xs={12}>

                    <Typography variant={"body1"} align={"center"}>
                        YaziciNuryBeats is more than just a music producer; he's a storyteller who uses the medium of
                        sound to weave narratives. Every beat, every track, tells a story—reflecting his creative vision
                        and personal journey within the music world. His commitment to authenticity and originality,
                        subtly combined with influences from more intense genres, has earned him respect among his peers
                        and a growing fan base. Whether you're an artist looking to collaborate on a unique sound, or a
                        fan seeking to delve deeper into the world of music, you've come to the right place. Explore the
                        site, immerse yourself in the music, and step into the world of YaziciNuryBeats.
                    </Typography>
                </Grid>
            </Grid>

        </Container>
    );
};

export default About;