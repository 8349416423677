import {updateObject} from "../utility";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
    cartItems: [],
    loading: false,
    error: null
}

const fetchStart = (state, action) => {
    return updateObject( state, {
        loading: true
     } );
};

const fetchSuccess = (state, action) => {
    // return updateObject( state, {
    //     cartItems: [...action.cartItems],
    //     loading: false,
    //     error: null
    //  } );
    return {
        loading: false,
        cartItems: [...action.cartItems],
        error: null
    }
};

const fetchFailure = (state, action) => {
    return updateObject( state, {
        loading: false,
        cartItems: [],
        error: action.errorMessage
     } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.CART_FETCH_START: return fetchStart(state, action);
        case actionTypes.CART_FETCH_SUCCESS: return fetchSuccess(state, action);
        case actionTypes.CART_FETCH_FAILURE: return fetchFailure(state, action);
        default:
            return state;
    }
};

export default reducer;