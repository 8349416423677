import React from 'react';
import {Button, Grid, Link, Paper, Typography} from "@mui/material";
import {formatDecimal} from "../../utils/formatText";
import {Link as RouterLink} from "react-router-dom";
import {routes} from "../../utils/routes";
import CartCheckoutButton from "./CartCheckoutButton";

const CartSummary = ({cartItems}) => {

    const totalPrice = cartItems.reduce((price, cartItem) => {
        return price + cartItem.beat_file.price;
    }, 0);

    return (
        <Paper sx={{p: 2, mt: 2}}>
            <Grid container spacing={4}>
                <Grid item xs={3}>
                    <Typography>
                        TOTAL
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography align={"right"} variant={"h4"}>
                        ${formatDecimal(totalPrice)}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{mt: 4}}>
                    <CartCheckoutButton/>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{color: "text.secondary", fontSize: 10}}>
                        By clicking the button you accept the product(s) License Agreement(s)<br/>

                        Please read our{" "}
                        <Link component={RouterLink} to={routes.homePage} color="inherit"
                              >
                            Refund Policy.
                        </Link>

                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default CartSummary;