import React from 'react';
import {Box} from "@mui/material";

const AuthNavigation = ({children}) => {
    return (
        <Box sx={{mt: 2}}>
            {children}
        </Box>
    );
};

export default AuthNavigation;