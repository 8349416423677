import React, {useEffect, useState} from 'react';
import {beatsService} from "../api/services/beatsService";
import {setSnack} from "../store/actions/snack";
import {connect} from "react-redux";
import Search from "../components/Beats/Search/Search";
import BeatsList from "../components/Beats/BeatsList";
import TriggerOnScrollEnd from "../components/TriggerOnScrollEnd";

const Beats = ({setSnack}) => {

    const [filters, setFilters] = useState(null)
    const [loadingFilters, setLoadingFilters] = useState(true)

    const [beats, setBeats] = useState([])
    const [loadingBeats, setLoadingBeats] = useState(true)

    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)

    const [search, setSearch] = useState("")
    const [bpm, setBpm] = useState("")
    const [mood, setMood] = useState("")
    const [genera, setGenera] = useState("")
    const [artist, setArtist] = useState("")

    useEffect(() => {
        beatsService.fetchFilters()
            .then((response) => {
                setLoadingFilters(false)
                setFilters(response.data)
            })
            .catch((errorResponse) => {
                setLoadingFilters(false)
                setSnack(errorResponse.message, "error")
            })
    }, [])

    useEffect(() => {
        setLoadingBeats(true)
        beatsService.fetchBeats({
            title: search,
            bpm,
            mood,
            genera,
            artist,
            page
        })
            .then((response) => {
                setLoadingBeats(false)
                if (page === 1)
                    setBeats(response.data.beats)
                else
                    setBeats(prevBeats => [...prevBeats, ...response.data.beats])
                setMaxPage(response.data.pages)
            })
            .catch((errorResponse) => {
                setLoadingBeats(false)
                setSnack(errorResponse.message, "error")
            })
    }, [page, search, bpm, mood, genera, artist])

    const changeQueryParams = (newValue, setValue) => {
        setValue(newValue)
        setPage(1)
    }

    const fetchMoreBeats = () => {
        if (page < maxPage)
            setPage(page + 1)
    }

    return (

        <TriggerOnScrollEnd maxHeight={"88vh"} onTarget={() => fetchMoreBeats()}>
            <Search
                filters={filters}
                search={search}
                setSearch={setSearch}
                bpm={bpm}
                setBpm={setBpm}
                mood={mood}
                setMood={setMood}
                genera={genera}
                setGenera={setGenera}
                artist={artist}
                setArtist={setArtist}
                changeQueryParams={changeQueryParams}
            />
            <BeatsList beats={beats}/>
        </TriggerOnScrollEnd>

    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity))
    };
};
export default connect(null, mapDispatchToProps)(Beats);