import React, {useState} from 'react';
import {Button} from "@mui/material";
import {AddShoppingCart} from "@mui/icons-material";
import AddToCartModal from "../Cart/AddToCartModal";

const BeatsAddToCartButton = ({beat}) => {

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    if (Object.keys(beat.files).length < 1){
        return (
            <Button
                fullWidth
                disabled={true}
                variant={"contained"}
            >
                Coming Soon
            </Button>
        )
    }

    return (
        <AddToCartModal beat={beat} handleClose={handleClose} open={open}>
            <Button
                fullWidth
                color={"secondary"}
                variant={"contained"}
                endIcon={<AddShoppingCart/>}
                onClick={() => setOpen(true)}
            >
                BUY NOW
            </Button>
        </AddToCartModal>
    );
};

export default BeatsAddToCartButton;