import React from 'react';
import {Box, Grid, IconButton} from "@mui/material";

const GoogleSignInButton = () => {
    return (
        <Grid container justifyContent={"center"} alignItems={"center"} sx={{mt: 2}}>
            <Grid item>
                <IconButton>
                    <Box id={"googleSignIn"} sx={{margin: "auto", width: 40, height: 40}}/>
                </IconButton>
            </Grid>
        </Grid>
    )
};

export default GoogleSignInButton;