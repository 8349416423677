import React, {useEffect, useState} from 'react';
import AppLayout from "../components/AppLayout";
import {beatsService} from "../api/services/beatsService";
import BeatsOverview from "../components/Beats/BeatsOverview";
import BeatsList from "../components/Beats/BeatsList";
import BeatsLicensingCarousel from "../components/Beats/BeatsLicensingCarousel";
import CenteredLoader from "../components/Loaders/CenteredLoader";
import Socials from "../components/Socials";
import MoneyBack from "../components/Beats/MoneyBack";

const HomePage = () => {

    const [loading, setLoading] = useState(false)
    const [beats, setBeats] = useState([])

    useEffect(() => {
        setLoading(true)
        beatsService.fetchBeats({page: 1, perPage: 11})
            .then((response) => {
                setBeats(response.data.beats)
                setLoading(false)
            })
            .catch((errorResponse) => {
                console.log(errorResponse.message)
                setLoading(false)
            })
    }, [])


    return (
        <>
            <BeatsOverview beats={beats}/>
            <BeatsLicensingCarousel/>
            <MoneyBack/>
            <Socials/>
        </>
    );
};

export default HomePage;