import React, {useRef, useState} from 'react';
import * as yup from "yup";
import {checkFileSize, checkSize, extractFileNameFromURL} from "../../../../../utils/formUtils";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Button, Grid, InputAdornment, TextField} from "@mui/material";
import ControlledTextField from "../../../../ControlledTextField";
import {PriceNumberFormat} from "../../../../../utils/formatInput";
import {beatsFilesService} from "../../../../../api/services/beatsFilesService";
import {setSnack} from "../../../../../store/actions/snack";
import {connect} from "react-redux";
import ButtonWithConfirmation from "../../../../ButtonWithConfirmation";
import SubmitButton from "../../../../SubmitButton";
import BeatFilesFieldsLayout from "./BeatFilesFieldsLayout";
import {tiers} from "../../../../../utils/tiers";

const schema = yup.object().shape({
    price: yup.number().typeError('Must be a number'),
    file: yup.mixed().nullable().test('fileSize', "File is too large", checkFileSize),
});


const UpdateBeatFile = ({beatFile, tierId, setSnack, updateBeatFiles, tierName}) => {
    const freeTier = tierName === tiers.FREE
    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        formState,
        reset,
        setValue,
        getFieldState
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            price: beatFile.price / 100,
            file: null
        }
    });

    const [loadingUpdateBeatFile, setLoadingUpdateBeatFile] = useState(false)
    const [loadingDeleteBeatFile, setLoadingDeleteBeatFile] = useState(false)

    const fileInput = useRef();

    const onSelectFile = (selectedFile) => {
        // console.log("change file")
        setValue("file", selectedFile.target.files[0], {shouldValidate: true, shouldTouch: true, shouldDirty: true})
    }

    const onSuccess = (data) => {
        setLoadingUpdateBeatFile(true)
        beatsFilesService.updateBeatFile(beatFile.id, {...data, price: data.price * 100})
            .then((response) => {
                setSnack(response.message, "success")
                updateBeatFiles({...response.data, price: response.data.price / 100}, tierName)
                reset({price: response.data.price / 100, file: null})
                setLoadingUpdateBeatFile(false)
            })
            .catch((errorResponse) => {
                setSnack(errorResponse.message, "error")
                setLoadingUpdateBeatFile(false)
            })
    }

    const onError = (errorData) => {

    }

    const onDelete = () => {
        setLoadingDeleteBeatFile(true)
        beatsFilesService.deleteBeatFile(beatFile.id)
            .then((response) => {
                setSnack(response.message, "success")
                updateBeatFiles(null, tierName)
                setLoadingUpdateBeatFile(false)
            })
            .catch((errorResponse) => {
                setSnack(errorResponse.message, "error")
                setLoadingUpdateBeatFile(false)
            })
    }

    return (

        <form onSubmit={handleSubmit(onSuccess, onError)}>
            <BeatFilesFieldsLayout
                onSelectFile={onSelectFile}
                freeTier={freeTier}
                beatFile={beatFile}
                fileInput={fileInput}
                loadingSubmit={loadingUpdateBeatFile}
                loadingDeleteBeatFile={loadingDeleteBeatFile}
                formState={formState}
                reset={reset}
                onDelete={onDelete}
                control={control}
            />
        </form>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity))
    };
};
export default connect(null, mapDispatchToProps)(UpdateBeatFile);