import React, {useEffect} from 'react';
import {logout, logoutAction} from "../../store/actions/auth";
import {connect} from "react-redux";

const Logout = ({logoutAction}) => {

    useEffect(() => {
        logoutAction()
    }, [logoutAction])

    return null
};


const mapDispatchToProps = dispatch => {
    return {
        logoutAction: () => dispatch(logoutAction()),
    };
};

export default connect(null, mapDispatchToProps)(Logout);