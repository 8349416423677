import React from 'react';
import {Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";

const LicenseModal = ({open, handleClose, licenseData}) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
        >
            <Container maxWidth={"md"} component={Paper} sx={{my: 4, py: 2}} >
                <Grid container alignItems={"center"} columns={24}>
                    <Grid item xs={22} md={23}>
                        <Typography variant={"h4"} sx={{my: 2}}>
                        License Preview
                        </Typography>
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <IconButton sx={{margin: "auto"}} onClick={() => handleClose()}>
                            <Close/>
                        </IconButton>
                    </Grid>
                </Grid>
                {licenseData}
            </Container>
        </Modal>
    );
};

export default LicenseModal;