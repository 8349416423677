import React from 'react';
import {Box} from "@mui/material";
import HighlightedBeat from "./HighlightedBeat";
import BeatsList from "./BeatsList";
import BrowseAllBeatsButton from "./BrowseAllBeatsButton";
import CenteredLoader from "../Loaders/CenteredLoader";

const BeatsOverview = ({beats}) => {

    const beatsCopy = [...beats]

    const highlightedBeat = beatsCopy.shift()

    return (
        <Box>
            <HighlightedBeat beat={highlightedBeat}/>
            <BeatsList beats={beatsCopy}/>
            <BrowseAllBeatsButton/>
        </Box>
    );
};

export default BeatsOverview;