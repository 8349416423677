import React from 'react';
import NavigationBar from "./NavigationBar/NavigationBar";
import {Box, Toolbar, Container} from "@mui/material";
import {Outlet} from "react-router-dom";
import Footer from "./Footer";

const AppLayout = ({props, children}) => {
    return (
        <>
            <NavigationBar/>
            <Toolbar/>
            <Box sx={{minHeight: "85.2vh"}}>
                <Outlet/>
            </Box>
            {/*<Container maxWidth={"md"}>*/}

            {/*</Container>*/}
            <Footer/>
        </>
    );
};

export default AppLayout;