import {free} from "./licenseData/free";
import {trackoutLease} from "./licenseData/trackoutLease";
import {lease} from "./licenseData/lease";
import {exclusive} from "./licenseData/exclusive";

const FREE = 'free'
const EXCLUSIVE = "exclusive"
const TRACKOUT = "trackout_lease"
const LEASE = "lease"

export const tiers = {
    FREE,
    EXCLUSIVE,
    TRACKOUT,
    LEASE
}


export const contents = {
    free: "MP3",
    trackout_lease: "Trackouts + WAV + MP3",
    lease: "Trackouts + WAV + MP3",
    exclusive: "Trackouts + WAV + MP3",
}

export const licenses = {
    free: "Free",
    trackout_lease: "Trackout Lease",
    lease: "Unlimited Lease",
    exclusive: "Exclusive",
}

export const licensesData = {

    free: free,
    trackout_lease: trackoutLease,
    lease: lease,
    exclusive: exclusive,
}