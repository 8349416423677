import React from 'react';
import {Box} from "@mui/material";

const AuthAlternativeActions = ({children}) => {
    return (
        <Box sx={{my: 4}}>
            {children}
        </Box>
    );
};

export default AuthAlternativeActions;