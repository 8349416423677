import React from 'react';
import {AppBar, Box, Button, IconButton, Toolbar} from "@mui/material";
import {Menu} from "@mui/icons-material";
import logo from "../../assets/images/logo.png";
import {useNavigate, Link} from "react-router-dom";
import CartBadge from "../Cart/Badge";
import {ShoppingCart, Login} from '@mui/icons-material';
import {routes} from "../../utils/routes";
import NavBarActionButtons from "./NavBarActionButtons";
import NavBarAuthActionButtons from "./NavBarAuthActionButtons";

const CustomAppBar = ({handleDrawerToggle, navItems, isAuthenticated}) => {

    const navigate = useNavigate();

    return (
        <AppBar component="nav" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{mr: 2, display: {sm: 'none'}}}
                >
                    <Menu/>
                </IconButton>
                <Box
                    variant="h6"
                    component="div"
                    sx={{flexGrow: 1}}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        <Box component={Link} to={routes.homePage} sx={{
                            width: 120
                        }}>
                            <img
                                src={logo}
                                alt="Logo"
                                height={54}
                                width={120}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                    {navItems.map((item, index) => (
                        <Button variant="text" sx={(theme) => {
                            return {color: theme.palette.text.primary}
                        }} key={index} onClick={() => navigate(item.path)}>
                            {item.label}
                        </Button>
                    ))}
                </Box>

                {isAuthenticated ? <NavBarAuthActionButtons/> : <NavBarActionButtons/>}

            </Toolbar>
        </AppBar>
    );
};

export default CustomAppBar;