import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import AdminBeatLayout from "../../components/Admin/Beats/AdminBeatLayout";
import BeatFile from "../../components/Admin/Beats/Beat/BeatFiles/BeatFile";
import FullPageLoader from "../../components/Loaders/FullPageLoader";
import {beatsFilesService} from "../../api/services/beatsFilesService";
import {setSnack} from "../../store/actions/snack";
import {connect} from "react-redux";
import {tiers} from "../../utils/tiers";

const AdminBeatFiles = ({setSnack}) => {
    const {beatId} = useParams();
    const [beatFiles, setBeatFiles] = useState([])
    const [loadingBeatFiles, setLoadingBeatFiles] = useState(true)


    useEffect(() => {
        beatsFilesService.fetchBeatFiles(beatId)
            .then((response) => {

                setBeatFiles(response.data)
                setLoadingBeatFiles(false)
            })
            .catch((errorResponse) => {
                setSnack(errorResponse.message, "error")
                setLoadingBeatFiles(false)
            })
    }, [])

    if (loadingBeatFiles)
        return <FullPageLoader/>

    const updateBeatFiles = (beatFile, tierName) => {
        const updatedBeatFiles = {
            ...beatFiles
        }
        updatedBeatFiles[tierName] = beatFile

        setBeatFiles(updatedBeatFiles)
    }

    return (
        <AdminBeatLayout>
            <BeatFile
                data={beatFiles}
                title={"Free Tier"}
                tierId={1}
                tierName={tiers.FREE}
                beatId={beatId}
                updateBeatFiles={updateBeatFiles}
            />
            <BeatFile
                data={beatFiles}
                title={"Exclusive Tier"}
                tierId={4}
                tierName={tiers.EXCLUSIVE}
                beatId={beatId}
                updateBeatFiles={updateBeatFiles}
            />
            <BeatFile
                data={beatFiles}
                title={"WAV Trackout Tier"}
                tierName={tiers.TRACKOUT}
                tierId={2}
                beatId={beatId}
                updateBeatFiles={updateBeatFiles}
            />
            <BeatFile
                data={beatFiles}
                title={"Lease Tier"}
                tierName={tiers.LEASE}
                tierId={3}
                beatId={beatId}
                updateBeatFiles={updateBeatFiles}
            />
        </AdminBeatLayout>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity))
    };
};
export default connect(null, mapDispatchToProps)(AdminBeatFiles);