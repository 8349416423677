const login = "/api/auth/login";
const register = "/api/auth/register";
const confirmEmail = "/api/auth/confirm-email"
const recoverPassword = "/api/auth/recover-password"
const changeRecoverPassword = "/api/auth/change-recovered-password"
const resendConfirmationEmail = "/api/auth/resend-confirmation"
const loginWithGoogle = "/api/auth/login-with-google"
const changePassword = "/api/auth/change-password"

const beatsList = "/api/beat"
const beatsFilters = "/api/beat/filters"
const beatFile = "/api/beat/file"
const userBeatFileList = "/api/beat/file/user"
const userBeatFileDownload = "/api/beat/file/user/download"

const cart = "/api/cart"

const order = "/api/order"

export const endpoints = {
    login,
    register,
    confirmEmail,
    recoverPassword,
    changeRecoverPassword,
    resendConfirmationEmail,
    loginWithGoogle,
    beatsList,
    beatFile,
    beatsFilters,
    cart,
    order,
    userBeatFileList,
    userBeatFileDownload,
    changePassword
}