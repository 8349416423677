import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import HomePage from "../pages/HomePage";
import Beats from "../pages/Beats";
import About from "../pages/About";
import Contact from "../pages/Contact";
import AppLayout from "../components/AppLayout";
import Settings from "../pages/Settings";
import Cart from "../pages/Cart";
import AdminBeats from "../pages/Admin/AdminBeats";
import UserBeats from "../pages/User/UserBeats";
import AdminCreateBeat from "../pages/Admin/AdminCreateBeat";
import AdminEditBeat from "../pages/Admin/AdminEditBeat";
import {beatsService} from "../api/services/beatsService";
import {routes} from "../utils/routes";
import AdminBeatFiles from "../pages/Admin/AdminBeatFiles";
import AdminLayout from "../components/Admin/AdminLayout";
import Beat from "../pages/Beat";
import Orders from "../pages/Orders";

const AppNavigation = () => {
    return (
        <Routes>
            <Route element={<AppLayout/>}>
                <Route index element={<HomePage/>}/>
                <Route path={"beats"} element={<Beats/>}/>
                <Route path={"beat/:beatId"} element={<Beat/>}/>
                <Route path={"about"} element={<About/>}/>
                <Route path={"contact"} element={<Contact/>}/>
                <Route path={"settings"} element={<Settings/>}/>
                <Route path={"cart"} element={<Cart/>}/>
                <Route path={"orders"} element={<Orders/>}/>

                <Route path={"admin"} element={<AdminLayout/>}>
                    <Route path={"beats"} element={<AdminBeats/>}/>
                    <Route path={"beat"} element={<AdminCreateBeat/>}/>
                    <Route path={"beat/:beatId"} element={<AdminEditBeat/>}/>
                    <Route path={"beat/:beatId/files"} element={<AdminBeatFiles/>}/>
                    <Route
                        path="*"
                        element={<Navigate to={routes.adminBeats} replace/>}
                    />
                </Route>

                <Route path={"user/beats"} element={<UserBeats/>}/>
                    <Route
                        path="*"
                        element={<Navigate to={routes.homePage} replace/>}
                    />
            </Route>
        </Routes>
    )
};


export default AppNavigation;