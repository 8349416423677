import buildClient from "../buildClient";
import {endpoints} from "../endpoints";

const createOrder = () => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.post(endpoints.order)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const fetchOrders = () => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.get(endpoints.order)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}




export const ordersService = {
    createOrder,
    fetchOrders
}