import React from 'react';
import {Container} from "@mui/material";

const AdminBeatLayout = ({children}) => {
    return (
        <Container maxWidth={"md"} sx={{py: 3}}>
            {children}
        </Container>
    );
};

export default AdminBeatLayout;