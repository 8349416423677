import React from 'react';
import {Link} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {routes} from "../../utils/routes";

const BeatTitleLink = ({beat, bold=true}) => {
    return (

        <Link component={RouterLink} to={routes.beat + beat.id} color="inherit"
              underline={"hover"}>
           {beat.title}
        </Link>
    );
};

export default BeatTitleLink;