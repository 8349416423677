import {Button, Container, Grid, IconButton, Modal, Paper, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import ShopTiers from "../Beat/ShopTiers";
import React, {useState} from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {authService} from "../../api/services/authService";
import {routes} from "../../utils/routes";
import ControlledPasswordField from "../ControlledPasswordField";
import AuthTextFieldArea from "../Auth/AuthTextFieldArea";
import {setSnack} from "../../store/actions/snack";
import {setAuthRedirectPath} from "../../store/actions/auth";
import {connect} from "react-redux";
import SubmitButton from "../SubmitButton";
import AuthForm from "../Auth/AuthForm";

const schema = yup.object().shape({
    oldPassword: yup.string().required('Required field').min(8, 'Must be at least 8 characters long'),
    password: yup.string().required('Required field').min(8, 'Must be at least 8 characters long'),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match').nullable()
});

const SettingsChangePasswordModal = ({open, handleClose, setSnack}) => {

    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)

    const {
        control,
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            oldPassword: "",
            password: "",
            confirmPassword: ""
        }
    });

    const onSuccess = (data) => {
        setLoading(true)

        authService.changePassword(data.oldPassword, data.password)
            .then((response) => {
                setSnack(response.message, "success")
                handleClose()
                // setAuthRedirectPath(routes.login)
            })
            .catch((error) => {
                setSnack(error.message, "error")
                setLoading(false)
            })

    }

    const onError = (error) => {
        // Handle Errors
        console.log(error)
    }

    return (
        <Modal
            open={open}
            onClose={() => handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{overflow: "scroll"}}
        >
            <Container maxWidth={"sm"} component={Paper} sx={{my: 4, py: 2}}>
                <AuthForm onSubmit={handleSubmit(onSuccess, onError)}>
                    <Grid container alignItems={"center"} columns={24}>
                        <Grid item xs={22} md={23}>
                            <Typography variant={"h4"} sx={{my: 2}}>
                                Change Password
                            </Typography>
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <IconButton sx={{margin: "auto"}} onClick={() => handleClose()}>
                                <Close/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <AuthTextFieldArea>
                        <ControlledPasswordField control={control} name={"oldPassword"} label={"Current Password"}
                                                 setShowPassword={setShowPassword} showPassword={showPassword}/>
                        <ControlledPasswordField control={control} name={"password"} label={"New Password"}
                                                 setShowPassword={setShowPassword} showPassword={showPassword}/>
                        <ControlledPasswordField control={control} name={"confirmPassword"}
                                                 label={"Confirm New Password"}
                                                 setShowPassword={setShowPassword} showPassword={showPassword}/>
                    </AuthTextFieldArea>
                    <Grid container spacing={2} sx={{mt: 3}}>
                        <Grid item xs={6}>
                            <Button
                                onClick={() => handleClose()}
                                fullWidth
                                variant={"outlined"}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <SubmitButton
                                text={"SUBMIT"}
                                loading={loading}
                            />
                        </Grid>

                    </Grid>
                </AuthForm>
            </Container>
        </Modal>

    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(SettingsChangePasswordModal);