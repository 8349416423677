import React from 'react';
import {FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const SearchSelectField = ({value, setValue, options, label}) => {

    return (
        <FormControl fullWidth>
            <InputLabel id="select-label">{label}</InputLabel>
            <Select
                labelId="select-label"
                id="select"
                value={value}
                label={label}
                variant={"standard"}
                onChange={setValue}
                MenuProps={{sx: {maxHeight: 400}}}
            >
                <MenuItem value={""}>None</MenuItem>
                {options.map((option, index) => {
                    return <MenuItem key={index} value={option}>{option}</MenuItem>
                })}
            </Select>
        </FormControl>
    );
};

export default SearchSelectField;