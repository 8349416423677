import React from 'react';
import {Button} from "@mui/material";
import {ordersService} from "../../api/services/ordersService";
import {openInNewTab} from "../../utils/beatsHelpers";

const CartCheckoutButton = () => {

    const onSubmit = () => {
        // const expressCheckoutElement = elements.create('expressCheckout');
        ordersService.createOrder()
            .then((response) => {
                window.location.replace(response.data.checkout_url)
            })
            .catch((errorResponse) => {
                console.log(errorResponse)
            })
    }

    return (
        <Button variant={"contained"} onClick={() => onSubmit()} fullWidth color={"secondary"}>
            Checkout
        </Button>
    );
};

export default CartCheckoutButton;