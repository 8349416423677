import React, {useEffect, useState} from 'react';
import {ordersService} from "../api/services/ordersService";
import {setSnack} from "../store/actions/snack";
import {connect} from "react-redux";
import OrdersList from "../components/Orders/OrdersList";
import CenteredLoader from "../components/Loaders/CenteredLoader";
import FullPageLoader from "../components/Loaders/FullPageLoader";
import EmptyOrders from "../components/Orders/EmptyOrders";

const Orders = ({setSnack}) => {
    const [loading, setLoading] = useState(true)
    const [orders, setOrders] = useState([])

    useEffect(() => {
        ordersService.fetchOrders()
            .then((response) => {
                setLoading(false)
                setOrders(response.data)
            })
            .catch((errorResponse) => {
                setLoading(false)
                setSnack(errorResponse.message, "error")
            })
    }, [])

    if (loading){
        return <FullPageLoader/>
    }

    if (orders.length < 1){
        return <EmptyOrders/>
    }

    return (
        <>
            <OrdersList orders={orders}/>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity))
    };
};
export default connect(null, mapDispatchToProps)(Orders);