import React, {useState} from 'react';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {checkFormat, checkSize} from "../../utils/formUtils";
import {Container} from "@mui/material";
import SelectBeatImage from "../../components/Admin/Beats/Beat/SelectBeatImage";
import BeatTextFieldArea from "../../components/Admin/Beats/Beat/BeatTextFieldArea";
import ControlledTextField from "../../components/ControlledTextField";
import BeatActionButtons from "../../components/Admin/Beats/Beat/BeatActionButtons";
import SubmitButton from "../../components/SubmitButton";
import {beatsService} from "../../api/services/beatsService";
import {useNavigate} from "react-router-dom";
import {clearAuthErrors, loginAction, loginWithGoogleAction} from "../../store/actions/auth";
import {connect} from "react-redux";
import {setSnack} from "../../store/actions/snack";
import {routes} from "../../utils/routes";
import BeatTextFields from "../../components/Admin/Beats/Beat/BeatTextFields";
import AdminBeatLayout from "../../components/Admin/Beats/AdminBeatLayout";


const schema = yup.object().shape({
    title: yup.string().required('Required field'),
    artist: yup.string().required('Required field'),
    mood: yup.string().required('Required field'),
    bpm: yup.string().required('Required field'),
    genera: yup.string().required('Required field'),
    previewUrl: yup.string().required('Required field'),
    duration: yup.string().required('Required field'),
    image: yup.mixed().required('Image Required').test('fileSize', "File is too large", checkSize).test('fileType', "Invalid image format", checkFormat),
});

const AdminCreateBeat = ({setSnack}) => {

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const defaultValues = {
        title: "",
        mood: "",
        bpm: "",
        artist: "",
        previewUrl: "",
        duration: "",
        genera: "",
    }

    const {
        control,
        handleSubmit,
        setValue,
        formState,
        setError,
        clearErrors
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues
    });

    const onSuccess = (data) => {
        setLoading(true)

        beatsService.createBeat(data)
            .then((response) => {
                setLoading(false)
                setSnack(response.message, "success")
                navigate(routes.adminBeat + `/${response.data.id}`)
            })
            .catch((errorResponse) => {
                setLoading(false)
                if (typeof errorResponse.message === 'object') {
                    for (let fieldName in defaultValues) {
                        if (defaultValues.hasOwnProperty(fieldName)) {
                            if (!!errorResponse.message[fieldName]) {
                                setError(fieldName, {type: "custom", message: errorResponse.message[fieldName]})
                            } else {
                                clearErrors(fieldName)
                            }
                        }
                    }
                } else {
                    setSnack(errorResponse.message, "error")
                }
            })

    }

    const onError = (errorData) => {

    }

    return (
        <AdminBeatLayout>
            <form onSubmit={handleSubmit(onSuccess, onError)}>
                <SelectBeatImage control={control} name={"image"} setValue={setValue} formState={formState}/>
                <BeatTextFields control={control}/>
                <SubmitButton text={"CREATE"} color={"success"} loading={loading}/>
            </form>
        </AdminBeatLayout>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity))
    };
};
export default connect(null, mapDispatchToProps)(AdminCreateBeat);