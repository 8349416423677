import React from 'react';
import {Button, Container} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {routes} from "../../utils/routes";

const BrowseAllBeatsButton = () => {
    const navigate = useNavigate()
    return (
        <Container maxWidth={"xs"} sx={{py: 2}}>
            <Button
                variant={"contained"}
                fullWidth

            color={"secondary"}
                onClick={() => navigate(routes.beats)}
            >
                Browse all beats
            </Button>
        </Container>
    );
};

export default BrowseAllBeatsButton;