import React from 'react';
import BeatTextFieldArea from "./BeatTextFieldArea";
import ControlledTextField from "../../../ControlledTextField";
import {DurationNumberFormat, NumberFormat} from "../../../../utils/formatInput";
import {InputAdornment} from "@mui/material";

const BeatTextFields = ({control}) => {
    return (
        <BeatTextFieldArea>
            <ControlledTextField control={control} name={"title"} label={"Title"}/>
            <ControlledTextField control={control} name={"artist"} label={"Artist"}/>
            <ControlledTextField control={control} name={"mood"} label={"Mood"}/>

            <ControlledTextField
                    name={"bpm"}
                    control={control}
                    label={"BPM"}
                    inputProps={{
                        inputComponent: NumberFormat,
                    }}/>
            <ControlledTextField control={control} name={"genera"} label={"Genera"}/>
            <ControlledTextField control={control} name={"previewUrl"} label={"Youtube URL"}/>
            <ControlledTextField
                    name={"duration"}
                    control={control}
                    label={"Duration"}
                    inputProps={{
                        inputComponent: DurationNumberFormat,
                    }}/>
        </BeatTextFieldArea>
    );
};

export default BeatTextFields;