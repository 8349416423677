import React from 'react';
import {Box, Container} from "@mui/material";
import {handleScroll} from "../utils/listItems";

const TriggerOnScrollEnd = ({maxHeight, onTarget, children}) => {
    return (
        <Box sx={{maxHeight: maxHeight, overflowY: "scroll"}} onScroll={(event) => handleScroll(event, onTarget)}>
            {children}
        </Box>
    );
};

export default TriggerOnScrollEnd;