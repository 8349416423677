import React, {useEffect, useState} from 'react';
import Header from "../../components/Admin/Beats/Header";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {beatsService} from "../../api/services/beatsService";
import BeatsList from "../../components/Admin/Beats/BeatsList/BeatsList";
import {useNavigate} from "react-router-dom";
import {routes} from "../../utils/routes";

const schema = yup.object().shape({
    search: yup.string(),
});


const AdminBeats = () => {

    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [beats, setBeats] = useState([])

    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)

    const [searchPerformed, setSearchPerformed] = useState(false);

    const {
        control,
        handleSubmit,
        getValues
    } = useForm({
        resolver: yupResolver(schema),
    });

    const fetchBeats = (search, page) => {
        setLoading(true)
        beatsService.fetchBeats({title: search, page, perPage: 10})
            .then((response) => {
                if (page === 1)
                    setBeats(response.data.beats)
                else
                    setBeats(prevBeats => [...prevBeats, ...response.data.beats])
                setLastPage(response.data.pages)
                setSearchPerformed(false);
                setLoading(false)
            })
            .catch((errorResponse) => {
                console.log(errorResponse.message)
                setLoading(false)
            })
    }

    useEffect(() => {
        if (!searchPerformed) {
            fetchBeats(getValues("search"), page)
        }
    }, [page])

    const onAddBeat = () => {
        navigate(routes.adminBeat)
    }

    const onSearchSuccess = (data) => {
        setSearchPerformed(true); // Set searchPerformed to true when a search is performed
        fetchBeats(data.search, 1);
        setPage(1);
    }

    const onSearchError = (data) => {
    }

    const fetchMoreBeats = () => {
        if (page < lastPage && !loading)
            setPage(page + 1)
    }

    return (
        <>
            <Header control={control} onSearch={handleSubmit(onSearchSuccess, onSearchError)} onAddBeat={onAddBeat}/>
            <BeatsList beats={beats} fetchMoreBeats={fetchMoreBeats} loading={loading}/>
        </>
    );
};

export default AdminBeats;