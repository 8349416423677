import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {connect} from "react-redux";
import {registerAction} from "../../store/actions/auth";
import AuthLayout from "../../components/Auth/AuthLayout";
import AuthTitle from "../../components/Auth/AuthTitle";
import AuthTextFieldArea from "../../components/Auth/AuthTextFieldArea";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import ControlledTextField from "../../components/ControlledTextField";
import ControlledPasswordField from "../../components/ControlledPasswordField";
import AuthAlternativeActions from "../../components/Auth/AuthAlternativeActions";
import SubmitButton from "../../components/SubmitButton";
import AuthNavigation from "../../components/Auth/AuthNavigation";
import GoogleSignInButton from "../../components/Auth/GoogleSignInButton";
import ThirdPartyText from "../../components/Auth/Login/ThirdPartyText";
import LinkToRegister from "../../components/Auth/Login/LinkToRegister";
import LinkToForgotPassword from "../../components/Auth/Login/LinkToForgotPassword";
import {routes} from "../../utils/routes";
import ForgotPasswordHelperText from "../../components/Auth/ForgotPassword/ForgotPasswordHelperText";
import {authService} from "../../api/services/authService";
import {setSnack} from "../../store/actions/snack";
import AuthForm from "../../components/Auth/AuthForm";

const {REACT_APP_GOOGLE_CLIENT_ID} = process.env;

const schema = yup.object().shape({
    email: yup.string().email("Must be valid email").required('Required field'),
});


const ForgotPassword = ({setSnack}) => {
    const [loading, setLoading] = useState(false)

    const {control, handleSubmit} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: ""
        }
    });

    const onSuccess = (data) => {
        setLoading(true)
        authService.recoverPassword(data.email)
            .then((response) => {
                setSnack(response.message, "success")
                setLoading(false)
            })
            .catch((errorResponse) => {
                setSnack(errorResponse.message, "error")
                setLoading(false)
            })
    }

    const onError = (error) => {
        // Handle Errors
        console.log(error)
    }

    return (
        <AuthLayout>
            <AuthForm onSubmit={handleSubmit(onSuccess, onError)}>
                <AuthTitle title={"Forgot Password"} backRoute={routes.login}/>
                <AuthTextFieldArea>
                    <ControlledTextField control={control} name={"email"} label={"Email"}/>
                </AuthTextFieldArea>
                <AuthAlternativeActions>
                    <ForgotPasswordHelperText/>
                </AuthAlternativeActions>
                <SubmitButton text={"Submit"} loading={loading}/>
            </AuthForm>

        </AuthLayout>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
    };
};
export default connect(null, mapDispatchToProps)(ForgotPassword);