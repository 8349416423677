import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {routes} from "../utils/routes";
import Register from "../pages/Auth/Register";
import Login from "../pages/Auth/Login";
import ConfirmEmail from "../pages/Auth/ConfirmEmail";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ChangeForgotPassword from "../pages/Auth/ChangeForgotPassword";
import ResendConfirmationEmail from "../pages/Auth/ResendConfirmationEmail";
import Logout from "../pages/Auth/Logout";

import {Outlet} from "react-router-dom";
import {setSnack} from "../store/actions/snack";
import {fetchCartItems} from "../store/actions/cart";
import {connect} from "react-redux";

const AuthNavigation = ({isAuthenticated, setSnack}) => {

    const navigate = useNavigate()

    useEffect(() => {
        if (isAuthenticated) {
            // setSnack("You are already logged in!", "warning")
            navigate(routes.homePage)
        }
    }, [isAuthenticated])

    return (
        <Routes>
            <Route element={<Outlet/>}>
                <Route path={"register"} element={<Register/>}/>
                <Route path={"logout"} element={<Logout/>}/>
                <Route path={"login"} element={<Login/>}/>
                <Route path={"confirmEmail"} element={<ConfirmEmail/>}/>
                <Route path={"forgotPassword"} element={<ForgotPassword/>}/>
                <Route path={"changeForgotPassword"} element={<ChangeForgotPassword/>}/>
                <Route path={"resendConfirmationEmail"} element={<ResendConfirmationEmail/>}/>
            </Route>
        </Routes>
    );
};

const mapStateToProps = state => {
    return {
        isAuthenticated: !!state.auth.token,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
        fetchCartItems: () => dispatch(fetchCartItems())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AuthNavigation);