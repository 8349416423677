import buildClient from "../buildClient";
import {endpoints} from "../endpoints";

const fetchBeatFiles = (beatId) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.get(endpoints.beatsList + `/${beatId}/file`)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const fetchUserBeatFiles = (title, page, perPage=10) => {
        return new Promise((resolve, reject) => {
        const apiClient = buildClient()


        const data = {
            title,
            page,
            per_page: perPage
        }

        apiClient.get(endpoints.userBeatFileList, {params: data})
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const createBeatFile = (beatId, tierId, data) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const formData = new FormData();

        formData.append("price", data.price)
        formData.append("tier_id", tierId)
        formData.append('file', data.file, data.file.name);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        apiClient.post(endpoints.beatsList + `/${beatId}/file`, formData, config)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const updateBeatFile = (beatFileId, beatFile) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const formData = new FormData();

        if (beatFile.price)
            formData.append("price", beatFile.price)
        if (beatFile.file)
            formData.append('file', beatFile.file, beatFile.file.name);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        apiClient.put(endpoints.beatFile + `/${beatFileId}`, formData, config)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

const deleteBeatFile = (beatFileId) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.delete(endpoints.beatFile + `/${beatFileId}`)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}


const downloadUserBeatFile = (orderBeatFileId) => {
        return new Promise((resolve, reject) => {
        const apiClient = buildClient("blob")

        const data = {
            order_beat_file_id: orderBeatFileId
        }

        apiClient.get(endpoints.userBeatFileDownload, {params: data})
            .then((response) => {
                resolve(response)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

export const beatsFilesService = {
    fetchBeatFiles,
    createBeatFile,
    updateBeatFile,
    deleteBeatFile,
    fetchUserBeatFiles,
    downloadUserBeatFile
}