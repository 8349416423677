import React from 'react';
import {Box, Button, Grid, IconButton, Typography} from "@mui/material";
import {openInNewTab} from "../../utils/beatsHelpers";
import {Close} from "@mui/icons-material";
import {cartService} from "../../api/services/cartService";
import {fetchCartItems} from "../../store/actions/cart";
import {connect} from "react-redux";
import {formatDecimal} from "../../utils/formatText";
import {contents, licenses, licensesData} from "../../utils/tiers";

const CartListItem = ({cartItem, setSelectedLicense, fetchCartItems}) => {




    const beat = cartItem.beat_file.beat

    const onDelete = () => {
        cartService.deleteCartItem(cartItem.id)
            .then((response) => {
                fetchCartItems()
            })
            .catch((errorResponse) => {

            })
    }

    return (
        <Grid container item xs={12} alignItems={"center"} spacing={2}>
            <Grid item xs={0} md={2} sx={{
                display: {xs: "none", md: "flex"}
            }}>
                <Box
                    sx={{
                        position: "relative", display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                    }}
                >
                    <Box
                        component={"img"}
                        src={beat.aws_image_url}
                        alt="Beat Image"
                        sx={{
                            width: 75,
                            height: 75,
                            borderRadius: 2,
                            margin: "auto",
                            cursor: 'pointer'
                        }}
                        onClick={() => openInNewTab(beat.preview_url)}
                    />
                </Box>
            </Grid>
            <Grid item xs={9} md={5} container alignItems={"center"}>
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            width: '100%',
                            fontWeight: "bold"
                        }}
                        variant={"h5"}
                    >
                        {beat.title}
                    </Typography>
                </Grid>
                <Grid item xs={12}

                      sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          color: "text.secondary"
                      }}>
                    <Typography
                        sx={{
                            display: "inline",
                            color: "text.primary"
                        }}
                        variant={"body1"}
                    >
                        {licenses[cartItem.beat_file.tier.name]}
                    </Typography>
                    <Typography sx={{
                        display: {xs: "none", md: "inline"}
                    }}>
                        {" - "}
                    </Typography>
                    <Typography

                        sx={{
                            display: {xs: "block", md: "inline"}
                        }}
                        color={"text.secondary"}>{contents[cartItem.beat_file.tier.name]}</Typography>
                </Grid>
            </Grid>
            <Grid item xs={3} md={1}>
                <Typography align={"right"}>
                    ${formatDecimal(cartItem.beat_file.price)}
                </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
                <Button
                    variant={"contained"}
                    color={"secondary"} fullWidth
                    onClick={() => setSelectedLicense(licensesData[cartItem.beat_file.tier.name])}>
                    read license
                </Button>
            </Grid>
            <Grid item xs sx={{
                display: {xs: "block", md: "none"}
            }}>
            </Grid>
            <Grid item xs={2} md={1}>
                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <IconButton aria-label="cart" onClick={() => onDelete()}>
                        <Close/>
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        fetchCartItems: () => dispatch(fetchCartItems())
    };
};
export default connect(null, mapDispatchToProps)(CartListItem);