import React from 'react';
import {Container, Grid} from "@mui/material";
import OrdersListItem from "./OrdersListItem";

const OrdersList = ({orders}) => {
    return (
        <Container maxWidth={"md"}>

            <Grid container>
                {orders.map((order, index) => {
                    return <OrdersListItem key={index} order={order}/>
                })}
            </Grid>
        </Container>
    )
};

export default OrdersList;