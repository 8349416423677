import React from 'react';
import {Typography} from "@mui/material";

const ForgotPasswordHelperText = () => {
    return (
        <Typography variant={"body2"}>
            Enter your account's email above. You'll receive an email with password reset instructions. Be sure to check your inbox and spam folder after submission.
        </Typography>
    );
};

export default ForgotPasswordHelperText;