import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {connect} from "react-redux";
import {clearAuthErrors, registerAction, setAuthRedirectPath} from "../../store/actions/auth";
import AuthLayout from "../../components/Auth/AuthLayout";
import AuthTitle from "../../components/Auth/AuthTitle";
import AuthTextFieldArea from "../../components/Auth/AuthTextFieldArea";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import ControlledTextField from "../../components/ControlledTextField";
import ControlledPasswordField from "../../components/ControlledPasswordField";
import AuthAlternativeActions from "../../components/Auth/AuthAlternativeActions";
import LinkToLogin from "../../components/Auth/Register/LinkToLogin";
import SubmitButton from "../../components/SubmitButton";
import AuthNavigation from "../../components/Auth/AuthNavigation";
import AcceptTermsAndConditions from "../../components/Auth/Register/AcceptTermsAndConditions";
import {setSnack} from "../../store/actions/snack";
import {authService} from "../../api/services/authService";
import {Navigate, useSearchParams} from "react-router-dom";
import {routes} from "../../utils/routes";
import AuthForm from "../../components/Auth/AuthForm";


const schema = yup.object().shape({
    password: yup.string().required('Required field').min(8, 'Must be at least 8 characters long'),
    confirmPassword: yup.string()
        .oneOf([yup.ref('password'), null], 'Passwords must match').nullable()
});


const ChangeForgotPassword = ({setSnack, setAuthRedirectPath}) => {
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)

    let [searchParams, setSearchParams] = useSearchParams();

    const recoveryCode = searchParams.get("recovery_code")

    const defaultValues = {
            password: "",
            confirmPassword: ""
        }

    const {
        control,
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues
    });

    const onSuccess = (data) => {
        setLoading(true)

        authService.changeRecoverPassword(recoveryCode, data.password)
                .then((response) => {
                    setSnack(response.message, "success")
                    setAuthRedirectPath(routes.login)
                })
                .catch((error) => {
                    setSnack(error.message, "error")
                    setLoading(false)
                })

    }

    const onError = (error) => {
        // Handle Errors
        console.log(error)
    }

    if (!recoveryCode) {
        return <Navigate to={routes.login} replace={true}/>
    }

    return (
        <AuthLayout>
            <AuthForm onSubmit={handleSubmit(onSuccess, onError)}>
                <AuthTitle title={"Change Password"}/>
                <AuthTextFieldArea>
                    <ControlledPasswordField control={control} name={"password"} label={"New Password"}
                                             setShowPassword={setShowPassword} showPassword={showPassword}/>
                    <ControlledPasswordField control={control} name={"confirmPassword"} label={"Confirm New Password"}
                                             setShowPassword={setShowPassword} showPassword={showPassword}/>
                </AuthTextFieldArea>
                <AuthAlternativeActions>
                </AuthAlternativeActions>
                <SubmitButton
                    text={"SUBMIT"}
                    loading={loading}
                />
            </AuthForm>
        </AuthLayout>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        setSnack: (message, severity) => dispatch(setSnack(message, severity)),
        setAuthRedirectPath: (path) => dispatch(setAuthRedirectPath(path))
    };
};
export default connect(null, mapDispatchToProps)(ChangeForgotPassword);