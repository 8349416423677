import React, {useState} from 'react';
import {Box, Button, Divider, Grid, IconButton, Paper, Typography} from "@mui/material";
import {formatDecimal, formatLeadingZeros} from "../../utils/formatText";
import RetryCheckoutButton from "./RetryCheckoutButton";
import {ArrowDownward, ArrowUpward} from "@mui/icons-material";
import moment from "moment";

const OrdersListItem = ({order}) => {

    const [showElements, setShowElement] = useState(false)


    let retryCheckout = order.status === 0

    let color = "warning.main"

    if (order.status > 0){
        color = "success.main"
    }
    else if (order.status < 0){
        color = "error"
    }

    const toggleElements = () => {
        setShowElement(!showElements)
    }

    return (
        <Grid container item xs={12} component={Paper} sx={{m: 1, p: 2}} spacing={2}>
            <Grid item container md={9}>
                <Grid item xs={12}>
                    <Typography variant={"h5"}>
                        Order #{formatLeadingZeros(order.id)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={"body1"} color={"text.secondary"}>
                        {moment(order.created_at).format("YYYY-MM-DD h:mm:ss")}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={"body1"}>
                        Total ${formatDecimal(order.price)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item md={3} container  spacing={1}>
                <Box width={"100%"} sx={{textAlign: "center"}}>
                    <Typography variant={"body1"} color={color}>
                        {order.status_message}
                    </Typography>
                </Box>
                <Grid item xs={12} sx={{display: retryCheckout ? "flex" : "none"}}>
                    <RetryCheckoutButton order={order}/>
                </Grid>
            </Grid>
            <Grid item xs={12} container
                  spacing={1}
                  sx={{
                      display: showElements ? "flex" : "none",
                  }}
            >
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                {order.beat_files.map((beatFile, index) => {
                    return (
                        <Grid container item xs={12} key={index}>
                            <Grid item xs={8}>
                                <Typography sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    width: '100%',
                                }}>
                                    {beatFile.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Box width={"100%"} sx={{textAlign: "right"}}>
                                    <Typography sx={{}}>
                                        ${beatFile.price && formatDecimal(beatFile.price)}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    )
                })}

                <Grid item xs={12}>
                    <Divider/>
                </Grid>
            </Grid>

            <Grid item xs={12} sx={{alignItems: "center"}}>
                <Box width={"100%"} sx={{textAlign: "center"}}>
                    <IconButton aria-label="delete" onClick={() => toggleElements()}>
                        {showElements ? <ArrowUpward/> : <ArrowDownward/>}
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    );
};

export default OrdersListItem;