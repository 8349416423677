import React from 'react';
import {Typography} from "@mui/material";

const SearchEmptyList = () => {
    return (
        <Typography variant={"h4"} align={"center"} sx={{p: 4, fontWeight: "bold"}}>
            No Results
        </Typography>
    );
};

export default SearchEmptyList;