import * as yup from "yup";
import {yupResolver} from '@hookform/resolvers/yup';
import {connect, useSelector} from "react-redux";
import {clearAuthErrors, loginAction, loginWithGoogleAction, registerAction} from "../../store/actions/auth";
import AuthLayout from "../../components/Auth/AuthLayout";
import AuthTitle from "../../components/Auth/AuthTitle";
import AuthTextFieldArea from "../../components/Auth/AuthTextFieldArea";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import ControlledTextField from "../../components/ControlledTextField";
import ControlledPasswordField from "../../components/ControlledPasswordField";
import AuthAlternativeActions from "../../components/Auth/AuthAlternativeActions";
import SubmitButton from "../../components/SubmitButton";
import AuthNavigation from "../../components/Auth/AuthNavigation";
import GoogleSignInButton from "../../components/Auth/GoogleSignInButton";
import ThirdPartyText from "../../components/Auth/Login/ThirdPartyText";
import LinkToRegister from "../../components/Auth/Login/LinkToRegister";
import LinkToForgotPassword from "../../components/Auth/Login/LinkToForgotPassword";
import AuthForm from "../../components/Auth/AuthForm";

const {REACT_APP_GOOGLE_CLIENT_ID} = process.env;

const schema = yup.object().shape({
    email: yup.string().email("Must be valid email").required('Required field'),
    password: yup.string().required('Required field').min(8, 'Must be at least 8 characters long'),
});


const Login = ({login, clearAuthErrors, errors, authLoading, loginWithGoogle}) => {
    const [showPassword, setShowPassword] = useState(false)
    const {
        control,
        handleSubmit,
        setError,
        clearErrors
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
            password: ""
        }
    });

    const responseGoogle = (response) => {
        loginWithGoogle(response.credential)
    }

    useEffect(() => {
        /* global google */
        google.accounts.id.initialize({
            client_id: REACT_APP_GOOGLE_CLIENT_ID,
            callback: responseGoogle
        })

        google.accounts.id.renderButton(
            document.getElementById("googleSignIn"),
            {size: "large", type: "icon", theme: "filled_blue", shape: "circle"}
        )

    }, [])

    const onSuccess = (data) => {
        login(data)
    }

    const onError = (error) => {
        // Handle Errors
        console.log(error)
    }

    useEffect(() => {
        clearAuthErrors()
    }, [])

    useEffect(() => {
        for (let fieldName in schema) {
            if (schema.hasOwnProperty(fieldName)) {
                if (errors[fieldName])
                    setError(fieldName, {type: "custom", message: errors[fieldName]})
                else
                    clearErrors(fieldName)
            }
        }
    }, [errors, setError, clearErrors])

    return (
        <AuthLayout>
            <AuthForm onSubmit={handleSubmit(onSuccess, onError)}>

                <AuthTitle title={"Login"}/>
                <AuthTextFieldArea>
                    <ControlledTextField control={control} name={"email"} label={"Email"}/>
                    <ControlledPasswordField control={control} name={"password"} label={"Password"}
                                             setShowPassword={setShowPassword} showPassword={showPassword}/>
                </AuthTextFieldArea>
                <LinkToForgotPassword/>
                <AuthAlternativeActions>
                    <ThirdPartyText/>
                    <GoogleSignInButton/>
                </AuthAlternativeActions>
                <SubmitButton text={"Login"} onSubmit={null} loading={authLoading}/>

            </AuthForm>
            <AuthNavigation>
                <LinkToRegister/>
            </AuthNavigation>
        </AuthLayout>
    )
}

const mapStateToProps = state => {
    return {
        errors: state.auth.errors,
        authLoading: state.auth.loading,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        login: (data) => dispatch(loginAction(data)),
        loginWithGoogle: (accessToken) => dispatch(loginWithGoogleAction(accessToken)),
        clearAuthErrors: () => dispatch(clearAuthErrors())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);