import React from 'react';
import {Box, Paper, Typography} from "@mui/material";
import moment from "moment";

const Footer = () => {
    return (
        <Paper>
            <Typography variant={"body2"} align={"center"} sx={{py: 2}}>
                © {moment().format('YYYY')} Yazici Nury Beats. All rights reserved
            </Typography>
        </Paper>
    );
};

export default Footer;