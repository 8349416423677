import React from 'react';
import {Button} from "@mui/material";
import {Add} from "@mui/icons-material";

const AddBeatButton = ({onAddBeat}) => {
    return (
      <Button variant="outlined" fullWidth startIcon={<Add />} onClick={() => onAddBeat()}>
        Add beat
      </Button>
    );
};

export default AddBeatButton;