import React from 'react';
import {
    Container,
    Grid,
    Typography
} from "@mui/material";
import BeatsListItem from "./BeatsListItem";
import SearchEmptyList from "./Search/SearchEmptyList";

const BeatsList = ({beats}) => {

    if (!beats || beats.length < 1) {
        return <SearchEmptyList/>
    }

    return (
        <Container maxWidth={"lg"} sx={{py: 2}}>
            <Grid container  sx={{display: {xs: "none", md: "flex"}}}>
                <Grid item md={1}>
                </Grid>
                <Grid item md={4} >
                    <Typography color={"text.secondary"}>
                        TITLE
                    </Typography>
                </Grid>
                <Grid item md={1}>
                    <Typography color={"text.secondary"}>
                        TIME
                    </Typography>
                </Grid>
                <Grid item md={1}>
                    <Typography color={"text.secondary"}>
                        BPM
                    </Typography>
                </Grid>
                <Grid item md={3}>
                    <Typography color={"text.secondary"}>
                        TAGS
                    </Typography>
                </Grid>
                <Grid item md={2}>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {
                    beats.map((beat, index) => (
                        <BeatsListItem key={index} beat={beat}/>
                    ))
                }
            </Grid>
        </Container>
    );
};

export default BeatsList;