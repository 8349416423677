import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/virtual';

import './Carousel.css';


import {Pagination} from "swiper";
import {Virtual} from 'swiper';
import {Box, Button, Grid, makeStyles, Paper, Typography, useTheme} from "@mui/material";


export const Carousel = ({data, setSelectedLicense}) => {

    const theme = useTheme();

    const customStyle = {
        '--swiper-pagination-color': theme.palette.secondary.main,
        '--swiper-pagination-bullet-inactive-color': theme.palette.text.secondary,
        '--swiper-pagination-bullet-inactive-opacity': '100%',
        // "& .swiper-slide": { width: "auto" },
    };

    return (
        <Swiper
            slidesPerView={"auto"}
            centeredSlides={true}
            pagination={{
                clickable: true,
            }}
            disabled
            initialSlide={2}
            modules={[Pagination]}
            className="mySwiper"
            style={customStyle}
        >
            {data.map((data, index) => {
                return <SwiperSlide key={index}>
                    <SliderContent data={data} setSelectedLicense={setSelectedLicense}/>
                </SwiperSlide>
            })}
        </Swiper>
    );
};

const SliderContent = ({data, setSelectedLicense}) => {
    return <Paper sx={{height: 450, width: 250, mx: 1, backgroundColor: data.popular ? "text.secondary" : null}}>
        <Typography align={"center"} variant={"h5"} sx={{py: 4, fontWeight: "bold"}}>
            {data.title}
        </Typography>
        <Typography align={"center"} variant={"body2"}>
            {data.exclusive ? "You get all rights" : "Yazici Nury Beats retains full rights"}
        </Typography>
        <Box height={200} sx={{py: 2}}>
            {data.features.map((feature, index) => {
                return <Typography key={index} align={"center"}>
                    &#183; {feature}
                </Typography>
            })}
        </Box>
        <Grid container>
            <Grid item xs={12} sx={{mx: 2}}>
                <Button variant={"contained"}
                        color={"secondary"} fullWidth onClick={() => setSelectedLicense(data.license)}>
                    read license
                </Button>
            </Grid>
        </Grid>
        {
            data.popular ?
                <Typography align={"center"} variant={"body2"} sx={{py: 2}}>
                    MOST POPULAR
                </Typography> : null
        }
    </Paper>
}

export default Carousel;