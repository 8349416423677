import React, {useState} from 'react';
import LoginRequired from "../components/LoginRequired";
import {Button, Container, Grid, Typography} from "@mui/material";
import {clearAuthErrors, loginAction, loginWithGoogleAction} from "../store/actions/auth";
import {connect} from "react-redux";
import jwt_decode from "jwt-decode";
import SettingsChangePasswordModal from "../components/Settings/SettingsChangePasswordModal";

const Settings = ({token}) => {

    const [modalOpen, setModalOpen] = useState(false)

    const handleModalClose = () => {
        setModalOpen(false)
    }
    const handleModalOpen = () => {
        setModalOpen(true)
    }

    let email = ""
    if (token) {
        email = jwt_decode(token).sub.email
    }

    let withGoogle = false
    if (token) {
        withGoogle = jwt_decode(token).sub.with_google
    }

    console.log(withGoogle)

    return (
        <LoginRequired>
            <Container maxWidth={"sm"}>
                <Typography variant={"h4"} sx={{
                    py: 3
                }}>
                    Credentials
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>
                            Email Address
                        </Typography>
                        <Typography variant={"subtitle1"} sx={{fontWeight: "bold"}}>
                            {email}
                        </Typography>
                    </Grid>
                    {
                        withGoogle ?
                            <Grid item xs={12}>

                                <Typography>
                                    Your account is linked with Google. Password changes must be done through Google and
                                    cannot be modified directly on this site.
                                </Typography>
                            </Grid> :

                            <Grid container item xs={12}>
                                <Grid container item xs={12} sm={8}>
                                    <Grid item xs={12}>
                                        <Typography variant={"body2"}>
                                            Password
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant={"subtitle1"} sx={{fontWeight: "bold"}}>
                                            ********
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Button
                                        onClick={() => handleModalOpen()}
                                        variant={"outlined"}
                                        color={"primary"} fullWidth
                                        sx={{mt: {xs: 2, sm: 0}}}
                                    >
                                        Change password
                                    </Button>
                                </Grid>
                            </Grid>

                    }

                </Grid>
                <SettingsChangePasswordModal handleClose={handleModalClose} open={modalOpen}/>

            </Container>
        </LoginRequired>
    );
};


const mapStateToProps = state => {
    return {
        token: state.auth.token,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        login: (data) => dispatch(loginAction(data)),
        loginWithGoogle: (accessToken) => dispatch(loginWithGoogleAction(accessToken)),
        clearAuthErrors: () => dispatch(clearAuthErrors())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);