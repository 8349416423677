import buildClient from "../buildClient";
import {endpoints} from "../endpoints";

const fetchCartItems = () => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.get(endpoints.cart)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const addItemToCart = (beatFileId) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            beat_file_id: beatFileId
        }

        apiClient.post(endpoints.cart, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}

const deleteCartItem = (cartItemId) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        apiClient.delete(endpoints.cart + `/${cartItemId}`)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })
    })
}


export const cartService = {
    fetchCartItems,
    addItemToCart,
    deleteCartItem
}