import React from 'react';
import {Controller} from "react-hook-form";
import {FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel} from "@mui/material";
import {Search} from "@mui/icons-material";

const SearchField = ({control, name, onSearch, label}) => {

    const preventDefault = (event) => {
        event.preventDefault();
    };

    return (
        <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({field: {onChange, value}, fieldState: {error}}) => (
                <FormControl variant={"standard"} fullWidth error={!!error}>
                    <InputLabel>{label}</InputLabel>
                    <Input
                        label={label}
                        value={value}
                        onChange={onChange}
                        endAdornment={<InputAdornment position="end">
                            <IconButton
                                aria-label="seatch"
                                // onClick={onSearch}
                                type={"submit"}
                                onMouseDown={preventDefault}
                            >
                                <Search/>
                            </IconButton>
                        </InputAdornment>}
                    />
                    <FormHelperText>{" "}</FormHelperText>
                </FormControl>)}
        />
    );
};

export default SearchField;