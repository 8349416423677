import React from 'react';
import {Checkbox, Grid, Link, Typography} from "@mui/material";

const AcceptTermsAndConditions = ({tosAccepted, setTosAccepted}) => {
    return (
        <Grid container alignItems={"center"}>
            <Grid item xs={2}>
                <Checkbox checked={tosAccepted} onClick={() => setTosAccepted(!tosAccepted)}/>
            </Grid>
            <Grid item xs={10}>
                <Typography variant={"body1"}>
                    I have read and agree to the YaziciNuryBeats&nbsp;
                    <Link href="#">Terms of Service</Link> and&nbsp;
                    <Link href="#">Privacy Policy</Link>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default AcceptTermsAndConditions;