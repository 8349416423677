import React from 'react';
import AppLayout from "../components/AppLayout";
import {Container, Grid} from "@mui/material";
import CartList from "../components/Cart/CartList";
import {connect} from "react-redux";
import CartSummary from "../components/Cart/CartSummary";
import EmptyCart from "../components/Cart/EmptyCart";
import LoginRequired from "../components/LoginRequired";

const Cart = ({cartItems}) => {
    return (
        <LoginRequired>
            <Container maxWidth={"lg"}>
                {cartItems.length > 0 ?
                    <Grid container spacing={2} sx={{p: 2}}>
                        <Grid item xs={12} md={12} lg={9}>
                            <CartList cartItems={cartItems}/>
                        </Grid>
                        <Grid item xs={12} md={12} lg={3}>
                            <CartSummary cartItems={cartItems}/>
                        </Grid>
                    </Grid> :
                    <EmptyCart/>}
            </Container>
        </LoginRequired>

    );
};

const mapStateToProps = state => {
    return {
        cartItems: state.cart.cartItems
    };
};

export default connect(mapStateToProps)(Cart);