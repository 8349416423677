import React, {useRef} from 'react';
import {Box, Card, CircularProgress, Container, Grid, ListItemButton, ListItemText} from "@mui/material";
import BeatsListItem from "./BeatsListItem";
import CenteredLoader from "../../../Loaders/CenteredLoader";
import {handleScroll} from "../../../../utils/listItems";

const BeatsList = ({beats, fetchMoreBeats, loading}) => {




    return (
        <Container maxWidth={"sm"} sx={{maxHeight: "72vh", overflowY: "scroll", p: 2}} onScroll={(event) => handleScroll(event, fetchMoreBeats)}>
            <Grid container spacing={2}>

                {
                    beats.map((beatData, index) =>
                        <Grid key={index} item xs={12}>
                            <BeatsListItem
                                beatData={beatData}/></Grid>)
                }
                {
                    loading ?
                        <Grid item xs={12}>
                            <CenteredLoader/>
                        </Grid> : null
                }
            </Grid>
        </Container>
    )

};

export default BeatsList;