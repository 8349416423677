import buildClient from "../buildClient";
import {endpoints} from "../endpoints";
import {SnackService} from "../../utils/snackService";
import {routes} from "../../utils/routes";

const register = (email, password) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            email,
            password
        }

        apiClient.post(endpoints.register, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const confirmEmail = (confirmationCode) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            email_confirm_code: confirmationCode,
        }

        apiClient.post(endpoints.confirmEmail, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const login = (email, password) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            email,
            password
        }

        apiClient.post(endpoints.login, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                if (errorResponse.status === 403 && errorResponse.data.message === "Email is not verified")
                    reject({...errorResponse.data, redirect: routes.resendConfirmationEmail})
                else
                    reject(errorResponse.data)
            })

    })
}

const recoverPassword = (email) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            email,
        }

        apiClient.post(endpoints.recoverPassword, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const changeRecoverPassword = (code, password) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            recovery_code: code,
            new_password: password
        }

        apiClient.post(endpoints.changeRecoverPassword, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const resendConfirmationEmail = (email) => {

    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            email,
        }

        apiClient.post(endpoints.resendConfirmationEmail, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}

const loginWithGoogle = (accessToken) => {
        return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            access_token: accessToken,
        }

        apiClient.post(endpoints.loginWithGoogle, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}


const changePassword = (oldPassword, newPassword) => {
    return new Promise((resolve, reject) => {
        const apiClient = buildClient()

        const data = {
            old_password: oldPassword,
            new_password: newPassword
        }

        apiClient.post(endpoints.changePassword, data)
            .then((response) => {
                resolve(response.data)
            })
            .catch((errorResponse) => {
                reject(errorResponse.data)
            })

    })
}


export const authService = {
    register,
    login,
    confirmEmail,
    recoverPassword,
    changeRecoverPassword,
    resendConfirmationEmail,
    loginWithGoogle,
    changePassword
}